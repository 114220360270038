import { Modal, Form, Input, Button, message } from "antd";
import axiosConfig from "../../util/axios";
import { useState } from "react";
import requestErrorHandler from "../../util/requestErrorHandler";
import useAuth from "../../hooks/useAuth";

type Props = {
  open: boolean;
  onCancel: () => void;
  username: string;
  key: any;
};

export default function ChangeUsername({
  open,
  onCancel,
  username,
  key,
}: Props) {
  const [form] = Form.useForm();
  const { user, logIn } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  async function handleSubmit(values: TruckData) {
    try {
      setIsSubmitting(true);
      const data = await axiosConfig.post(`truck-owners/change-username`, {
        ...values,
      });
      const truckOwner: TruckOwner = data.data.truckOwner;
      const updatedUser: User | any = { ...user, truckOwner };

      logIn(updatedUser!);
      message.success(`Username Edited successfully!`);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
      onCancel();
    }
  }

  return (
    <div key={key} id={key}>
      <Modal
        // key={key}
        footer={null}
        open={open}
        title="Change Username"
        onCancel={onCancel}
      >
        <Form
          key={key}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={truckInfo!}
        >
          <Form.Item
            rules={[{ required: true, message: "User Name cannot be blank" }]}
            name="userName"
            label="User Name"
            initialValue={username}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
