import { Button, Form, Input, message } from "antd";
import { useState } from "react";
// import { navigate, Redirect } from "@reach/router";
import styles from "../login.module.css";
import axiosConfig from "../../../util/axios";
import AuthContainer from "../../../components/AuthContainer";
import { EmailMessage } from "../SignUp";
import requestErrorHandler from "../../../util/requestErrorHandler";

export default function ResetPassword() {
  const [form] = Form.useForm();

  // const [isLoading] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<String>("");
  const [isSuccessful, setIsSuccessful] = useState<boolean>();

  async function handleSubmit(values: any) {
    setEmail(values.email);
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axiosConfig.post("truck-owners/reset-password", {
        email: String(values.email).toLowerCase(),
      });
      message.success("Email submitted Successfully");
      setIsSuccessful(true);
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }
  // if (user) return <Redirect from="/" to="/builders/buy" noThrow />;

  if (isSuccessful) {
    return (
      <EmailMessage onClose={() => setIsSuccessful(false)}>
        <p>
          A Verification email has been sent to <b>{email + " ."}</b>
          <span>Kindly check your email to complete the process. </span>
        </p>
      </EmailMessage>
    );
  }

  return (
    <AuthContainer title="Reset Password" showBack>
      <>
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          className={styles.form}
        >
          <Form.Item
            name="email"
            label="Email Address"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isLoading}
          >
            SUBMIT
          </Button>
        </Form>
      </>
    </AuthContainer>
  );
}
