import React, { ReactNode } from "react";
import styles from "./styles.module.css";
export default function Container({
  children,
  className,
}: {
  children: ReactNode;
  className?: any;
}) {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
}
