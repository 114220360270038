import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Upload,
  Col,
  Row,
  Alert,
} from "antd";
import axiosConfig from "../../util/axios";
import { useState } from "react";
import styles from "./styles.module.css";
import { DriverCard } from "../TruckCard/Info";
import uploadToS3 from "../../util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import requestErrorHandler from "../../util/requestErrorHandler";

type Props = {
  open: boolean;
  onCancel: () => void;
  callback: () => void;
  orderRequestId: string;
  customerTruck?: TruckData;
};

export default function Driverinfo({
  open,
  onCancel,
  callback,
  orderRequestId,
  customerTruck,
}: Props) {
  const [form] = Form.useForm<User>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(true);

  async function handleSubmit(values: any) {
    // order/add-driver-info

    setIsSubmitting(true);
    try {
      await axiosConfig.put("order/add-driver-info", {
        ...values,
      });
      message.success("Driver Information Updated!");
      callback();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  const truck = customerTruck;
  const render = showPrompt && truck?.driverName;
  return (
    <Modal
      footer={null}
      open={open}
      title="Choose Driver for this Trip"
      onCancel={() => {
        onCancel();
        message.warning("Please select a Driver in order to Accept Order");
      }}
    >
      {render ? (
        <div>
          {/* <Alert message="Choose Driver for this Trip" /> */}

          <button
            className={styles["option-btn"]}
            onClick={() => {
              callback();
              onCancel();
              message.success("Driver Selected!");
            }}
          >
            <h3> Current Driver</h3>
            <DriverCard title="" truck={truck!} />
          </button>
          <button
            className={styles["option-btn"]}
            onClick={() => setShowPrompt(false)}
          >
            <h3>Add New Driver</h3>
          </button>
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={user}
        >
          {/* <Form.Item
            rules={[{ required: true, message: "Driver Name cannot be blank" }]}
            name="driverName"
            label="Driver Name"
          >
            <Input />
          </Form.Item> */}
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Driver Name cannot be blank" },
                ]}
                name="driverFirstName"
                label="Driver First Name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Driver Name cannot be blank" },
                ]}
                name="driverLastName"
                label="Driver Last Name"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Driver Phone"
                name="driverPhone"
                rules={[{ required: true, message: "Phone cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="driverPhoto"
                label="Driver Image"
                rules={[
                  { required: true, message: "Driver Image cannot be blank" },
                ]}
                valuePropName="file"
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <Button icon={<ArrowUpOutlined />}>
                    Upload Driver Image
                  </Button>
                  <br />
                  <small>
                    Image should not be greater than <b>20MB</b>
                  </small>
                </Upload>
              </Form.Item>
              <Alert
                message="Please provide Driver's recent Passport not greater than 20MB"
                type="info"
              />
            </Col>
          </Row>

          <Form.Item
            rules={[{ required: true, message: "Tonnage cannot be blank" }]}
            name="orderRequestId"
            label="Tonnage"
            initialValue={orderRequestId}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
