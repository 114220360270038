const getImageSize = (size?: ImageSize) => {
  switch (size) {
    case "large":
      return "120px";
    case "medium":
      return "70px";
    case "small":
      return "50px";
    case "smaller":
      return "30px";
    case "smallest":
      return "20px";
    default:
      return "60px";
  }
};

export default getImageSize;
