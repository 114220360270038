import dbImg1 from "../assets/images/main-owner.png";
import dbImg2 from "../assets/images/main-renter.png";
import money from "../assets/images/cash.png";
import dummy from "../assets/images/avatar.png"

const data = {
    users: [
      {
        key: "1",
        name: "Charles Freeman",
        email: "charlesfreeman@gmail.com",
        phone:"+234 23 094 93",
        designation: ["HR"],
      },
      {
        key: "2",
        name: "Morgan Cloe",
        email: "charlesfreeman@gmail.com",
        phone:"+234 23 094 93",
        designation: ["Transport", "logistics"],
      },
      {
        key: "3",
        name: "Tomiwa Egbo",
        email: "tome@gmail.com",
        phone:"+234 23 094 93",
        designation: ["MD"],
      },
      {
        key: "4",
        name: "Moshood Alimi",
        email: "moshmosh@gmail.com",
        phone:"+234 23 094 93",
        designation: ["Security"],
      },
    ],
    companies: [
      {
        key: "1",
        name: "Apple",
        address: "New York No. 1 Lake Park",
        phone: '+1 213 556',
        tags: ["Phone", "Macbook"],
      },
      {
        key: "2",
        name: "Google",
        address: "London No. 1 Lake Park",
        phone: '+1 5563 556',
        tags: ["Search", "Phone", "Pixel"],
      },
      {
        key: "3",
        name: "MicroSoft",
        address: "Sidney No. 1 Lake Park",
        phone: '+1 873 123',
        tags: ["cool", "teacher"],
      },
      {
        key: "4",
        name: "NetFlix",
        address: "Cairo Sit Side",
        phone: '+1 312 6509',
        tags: ["Film", "Hollywood"],
      },
      {
        key: "5",
        name: "Google",
        address: "London No. 1 Lake Park",
        phone: '+1 5563 556',
        tags: ["Search", "Phone", "Pixel"],
      },
      {
        key: "6",
        name: "MicroSoft",
        address: "Sidney No. 1 Lake Park",
        phone: '+1 873 123',
        tags: ["cool", "teacher"],
      },
      {
        key: "7",
        name: "NetFlix",
        address: "Cairo Sit Side",
        phone: '+1 312 6509',
        tags: ["Film", "Hollywood"],
      },
      {
        key: "8",
        name: "Google",
        address: "London No. 1 Lake Park",
        phone: '+1 5563 556',
        tags: ["Search", "Phone", "Pixel"],
      },
      {
        key: "9",
        name: "MicroSoft",
        address: "Sidney No. 1 Lake Park",
        phone: '+1 873 123',
        tags: ["cool", "teacher"],
      },
      {
        key: "10",
        name: "NetFlix",
        address: "Cairo Sit Side",
        phone: '+1 312 6509',
        tags: ["Film", "Hollywood"],
      },
    ],


cardData : [
  {
    id: 0,
    image: money,
    title: "Transaction Overview",
    value: 10,
    type:"finance",
    info: [
    
      // {
      //   title:"Balance",
      //   value: 15000
      // },
      // {
      //   title:"Total Withdrawals",
      //   value: 20000
      // },
      // {
      //   title:"Total Earnings",
      //   value: 200000
      // },
      
      {
        title:"Total Earnings This Month",
        amountPaidThisMonth: 15000
      },
      {
        title:"Total Earnings This Year",
        amountPaidThisYear: 20000
      },
      {
        title:"Total Amount Earned",
        totalAmountDue
        : 200000
      },
      
     
      
    ]
  },
  {
    id: 1,
    image: dbImg2,
    title: "Truck Overview",
    value: 25,
    type:"standard",
    info: [
      {
        title:"Active",
        value: 13
      },
    
      {
        title: "Inactive",
        value: 7
      },
      {
        title: "Total Trucks",
        value: 20
      },
    ]
    // info: [
    //   {
    //     title:"Rented",
    //     value: 13
    //   },
    
    //   {
    //     title: "Available",
    //     value: 7
    //   },
    //   {
    //     title: "Total Trucks",
    //     value: 20
    //   },
    // ]
  },
  {
    id: 2,
    image: dbImg1,
    title: "Rental Operations",
    value: 25,
    type:"standard",
    info: [
      {
        title:"Pending",
        value: 2
      },
      {
        title: "In Progress",
        value: 3
      },
      {
        title: "Total",
        value: 5
      }
    ]
  },
  // {
  //   id: 3,
  //   image: dbImg3,
  //   title: "Available",
  //   value: 15,
  //   type:"standard",
  //   info: [
  //     {
  //       title:"Rented",
  //       value: 13
  //     },
      
  //   ]
  // },
],

}
export const bankAccounts:AccountCardProps[] = [ 
    {
      accountName:"Kelz",
      accountNumber:"0216975162",
      bankName:"GTB"
    },
    {
      accountName:"Bullish",
      accountNumber:"0212204375",
      bankName:"Polaris"
    },
    {
      accountName:"Kelz",
      accountNumber:"212204545",
      bankName:"Zenith"
    }
  ]

  export const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Abuja",
  ]; 

export const dummyImage = dummy

export default data
