import { UploadRequestOption } from "rc-upload/lib/interface";
import { Buffer } from "buffer";
import axios from "axios";

// Buffer.from("anything", "base64");

window.Buffer = window.Buffer || Buffer;

const API_URL = process.env.REACT_APP_MATPRO_API;

export default async function uploadToS3({
  file,
  data: _data,
  onSuccess,
  onError,
  filename,
}: UploadRequestOption) {
  // return "https://picsum.photos/200";
  try {
    const formData: any = new FormData();

    // // Update the formData object
    formData.append("file", file!, filename);

    const { data } = await axios.post(`${API_URL}/upload`, formData);

    onSuccess?.(
      data.location,
      //@ts-ignore
      file
    );
    // return "https://picsum.photos/200";
    return data.location;
  } catch (error) {
    //@ts-ignore
    // return "https://picsum.photos/200";
    onError?.(error);
  }
}
