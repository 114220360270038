import "./App.css";
import { ConfigProvider } from "antd";
import Router from "./router";
import { AppContextProvider } from "./context/AppContext";

function App() {
  return (
    <AppContextProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#096dd9",
            fontFamily: `'Montserrat', sans-serif`,
            colorHighlight: "rgb(231, 242, 247)",
            blue: "#096dd9",
            colorInfoHover: "rgb(231, 242, 247)",
            colorInfoActive: "rgb(231, 242, 247)",
            colorPrimaryActive: "rgb(231, 242, 247)",
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </AppContextProvider>
  );
}

export default App;
