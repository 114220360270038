import { Button, Col, Form, Input, Row, Upload, Select, message } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import uploadToS3 from "../../util/uploadToS3";
import axiosConfig from "../../util/axios";
import { states } from "../../store/data";
import useToken from "../../hooks/useToken";
import PageSpinner from "../../components/PageSpinner";
import AuthContainer from "../../components/AuthContainer";
import AppContext from "../../context/AppContext";
import requestErrorHandler from "../../util/requestErrorHandler";

// import uploadToS3Beta from "../../util/uploadToS3Beta";

const { Option } = Select;

export default function SignupNext() {
  const navigate = useNavigate();
  // const [localGovernment, setLocalGovernmanet] = useState<any>([]);
  // const [selectedLocalGovernment, setSelectedLocalGovernmanet] = useState<any>(
  //   []
  // );
  const { email, isLoading, verified, tokenUser } = useToken();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0);
  const { formValues, setFormValues } = useContext(AppContext);

  const handleSubmit = async (values: any) => {
    // naviogate("/login");

    setIsSubmitting(true);
    try {
      await axiosConfig.post("truck-owners/create", { ...formValues });
      message.success("Account created successfully!");
      navigate("/login");
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // const [stateSearchText, setStateSearchText] = useState<string>("");

  // const filteredStates = useMemo(() => {
  //   if (!stateSearchText) {
  //     // If the search text is empty, return all services
  //     return states;
  //   }
  //   const lowerCasestateSearchText = stateSearchText.toLowerCase();
  //   return states.filter((state) =>
  //     state.toLowerCase().includes(lowerCasestateSearchText)
  //   );
  // }, [stateSearchText, states]);

  // const getLGA = async () => {
  //   await axiosConfig
  //     .get("nigerianstate")
  //     .then((res) => {
  //       setLocalGovernmanet(res.data.data);
  //     })
  //     .catch((error) => {});
  // };

  // useEffect(() => {
  //   getLGA();
  // }, []);
  // const handleChange = (e: any) => {
  //   const ordered = localGovernment.filter((lga: any) => e === lga.state)[0]
  //     .Lgas;
  //   setSelectedLocalGovernmanet(ordered);
  // };

  if (isLoading || verified === undefined)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <PageSpinner />
      </div>
    );
  if (verified === false) {
    navigate("/");
    return <></>;
  }

  return (
    <AuthContainer
      showBack={step === 1}
      onPressBack={() => setStep(0)}
      title="Register as a Truck Owner"
    >
      <>
        <Form
          className={styles["signup-form"]}
          onFinish={handleSubmit}
          layout="vertical"
          onFieldsChange={(e, all) => {
            let obj: any = {};
            all.map((item) => (obj[item.name.toString()] = item.value));
            setFormValues({ ...formValues, ...obj });
          }}
        >
          {step === 0 ? (
            <>
              <Row gutter={10}>
                <Col md={12} sm={24}>
                  <Form.Item
                    initialValue={tokenUser?.adminFirstName}
                    label="First Name"
                    name="adminFirstName"
                    rules={[
                      { required: true, message: "First Name cannot be empty" },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24}>
                  <Form.Item
                    initialValue={tokenUser?.adminLastName}
                    label="Last Name"
                    name="adminLastName"
                    rules={[
                      { required: true, message: "Last Name cannot be empty" },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                initialValue={email}
                label="Email"
                name="adminEmail"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Email cannot be empty",
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>

              <Row gutter={10}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Phone"
                    name="contactNumber"
                    rules={[
                      { required: true, message: "Phone cannot be empty" },
                      {
                        pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                        message: "Please provide a valid phone number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      { required: true, message: "State cannot be empty" },
                    ]}
                  >
                    <Select showSearch size="middle">
                      {states.map((state) => (
                        <Option value={state} key={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button
                onClick={() => setStep(1)}
                block
                type="primary"
                size="large"
              >
                Proceed
              </Button>
            </>
          ) : (
            <>
              <Form.Item
                label="Business Name"
                name="businessName"
                rules={[
                  { required: true, message: "Business Name cannot be empty" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Business Address"
                name="businessAddress"
                rules={[
                  {
                    required: true,
                    message: "Business Address cannot be empty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="businessLogo"
                label="Upload Business Logo"
                valuePropName="file"
                rules={[
                  {
                    required: false,
                    message: "Business logo cannot be empty",
                  },
                ]}
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  // accept=""
                >
                  <Button size="large" icon={<ArrowUpOutlined />}>
                    Upload business logo
                  </Button>
                  <br />
                  <small>
                    Image should not be greater than <b>20MB</b>
                  </small>
                </Upload>
              </Form.Item>
              {/* <Row gutter={10}>
          
            {selectedLocalGovernment.length > 0 && (
              <Col xs={24} md={12}>
                <Form.Item
                  name="localGovernment"
                  label="LGA"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {selectedLocalGovernment.map((lgas: any) => (
                      <Option size="large" value={lgas} key={lgas}>
                        {lgas}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row> */}
              <Form.Item
                label="Password"
                name="adminPassword"
                hasFeedback
                rules={[
                  {
                    min: 6,
                    required: true,
                    message: "Password must be at least 6 characters",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["adminPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("adminPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Button
                loading={isSubmitting}
                htmlType="submit"
                block
                type="primary"
                size="large"
              >
                SUBMIT
              </Button>
            </>
          )}
        </Form>
        <div className={styles["form-footer"]}>
          Already have an account?{" "}
          <Button
            className={styles["form-footer-button"]}
            onClick={() => navigate("/login")}
            type="link"
          >
            Sign In
          </Button>
        </div>
      </>
    </AuthContainer>
  );
}
