import { Modal, Form, Input, Button, Upload, Select, Row, Col } from "antd";
import useAuth from "../../hooks/useAuth";
import uploadToS3 from "../../util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import useTruckResources from "../../hooks/useTruckResources";
import requestErrorHandler from "../../util/requestErrorHandler";

type Props = {
  open: boolean;
  onCancel: () => void;
  truckInfo: TruckData | null;
  callback?: () => void;
};

const { Option } = Select;

export default function EditTruck({
  open,
  onCancel,
  truckInfo,
  callback,
}: Props) {
  const { user } = useAuth() as {
    user: User;
  };

  const truckOwnerId = user?.truckOwner?.id || "";

  const [form] = Form.useForm();

  // const [tonnage] = useState<number | undefined>();
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const {
    brands,
    materials,
    truckProfile,
    zones,
    isSubmitting,
    getBrands,
    getMaterials,
    getTruckProfiles,
    getZones,
    handleSubmit,
  } = useTruckResources();

  // useEffect(() => {
  //   initializeZones();
  // }, [truckInfo]);

  const handleTonnageChange = (e: any) => {
    const currentTonnage = truckProfile?.find(
      (profile: TruckProfile) => profile.id === e
    )?.tonnage;

    form.setFieldValue("tonnage", currentTonnage);
  };
  // const [defaultZones, setDefaultZones] = useState<any>();

  const defaultZones = truckInfo?.serviceLocation.map(
    (item) => item.id + "&" + item.name + "&" + item.description
  );
  const defaultMaterials = truckInfo?.materials?.map(
    (mat) => mat.materialId + "&" + mat.materialName
  );

  useEffect(() => {
    Promise.all([getTruckProfiles(), getBrands(), getZones(), getMaterials()])
      .catch((error) => {
        requestErrorHandler(error);
        setHasError(true);
      })
      .finally(() => setLoadingData(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div key={truckInfo?.id} id={truckInfo?.id}>
      <Modal
        // key={truckInfo?.id}

        footer={null}
        open={open}
        title="Edit Truck"
        onCancel={onCancel}
      >
        <Form
          key={truckInfo?.id}
          form={form}
          layout="vertical"
          onFinish={(values) =>
            handleSubmit(values, truckInfo!, "edit", callback)
          }
          // initialValues={truckInfo!}
        >
          {/* <Form.Item
            rules={[{ required: true, message: "Truck Tag cannot be blank" }]}
            name="vehicleTag"
            label="Truck Tag"
          >
            <Input />
          </Form.Item> */}
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[{ required: true }]}
                initialValue={truckInfo?.brand}
              >
                <Select disabled={loadingData || hasError}>
                  {brands?.map((brand) => (
                    <Option value={brand.brandName} key={"daf"}>
                      {brand.brandName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                name="materials"
                label="Materials"
                rules={[{ required: true }]}
                initialValue={defaultMaterials}
              >
                <Select
                  defaultValue={defaultMaterials}
                  disabled={loadingData || hasError}
                  mode="multiple"
                >
                  {materials?.map((mat) => (
                    <Option value={mat.id + "&" + mat.name} key={mat.id}>
                      <span style={{ textTransform: "capitalize" }}>
                        {mat.name}{" "}
                      </span>
                      <br />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={24} xs={24}>
              <Form.Item
                name="truckProfileId"
                label="Truck Profile"
                rules={[{ required: true }]}
                initialValue={truckInfo?.truckProfileId}
              >
                <Select
                  disabled={loadingData || hasError}
                  onChange={handleTonnageChange}
                >
                  {truckProfile &&
                    truckProfile?.map((profile: TruckProfile) => (
                      <Option value={profile.id} key={profile.id}>
                        {profile.type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[{ required: true, message: "Model cannot be blank" }]}
                name="model"
                label="Year"
                initialValue={truckInfo?.model}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Truck age cannot be blank",
                  },
                ]}
                name="age"
                label="Truck Age"
                initialValue={truckInfo?.age}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Head color cannot be blank" },
                ]}
                name="headColor"
                label="Head Color"
                initialValue={truckInfo?.headColor}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Bucket Color cannot be blank" },
                ]}
                name="bucketColor"
                label="Bucket Color"
                initialValue={truckInfo?.bucketColor}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Plate Number cannot be blank",
                  },
                ]}
                name="plateNumber"
                label="Plate Number "
                initialValue={truckInfo?.plateNumber}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="picture"
                label="Upload Truck Image"
                valuePropName="file"
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
                initialValue={truckInfo?.picture}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <Button icon={<ArrowUpOutlined />}>Upload Truck Image</Button>
                  <br />
                  <small>
                    Image should not be greater than <b>20MB</b>
                  </small>
                </Upload>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="vehicleTag"
                label="Upload Door Jamb Sticker"
                rules={[
                  {
                    required: true,
                    message: "Door Jamb Sticker cannot be blank",
                  },
                ]}
                valuePropName="file"
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
                initialValue={truckInfo?.vehicleTag}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <Button icon={<ArrowUpOutlined />}>
                    Upload Door Jamb Sticker
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            rules={[{ required: true, message: "Tonnage cannot be blank" }]}
            name="truckOwnerId"
            // label="Tonnage"
            initialValue={truckOwnerId}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="serviceLocation"
            label="Geographical Coverage Area"
            rules={[{ required: true }]}
            initialValue={defaultZones}
          >
            <Select
              mode="multiple"
              defaultValue={defaultZones}
              disabled={loadingData || hasError}
            >
              {zones?.map((zone) => (
                <Option
                  value={zone.id + "&" + zone.name + "&" + zone.description}
                  key={zone.id}
                >
                  {zone.name}
                </Option>
              ))}
              {/* {[1, 2, 3].map((item) => (
                <Option value={item} key={item + 1}>
                  {item}
                </Option>
              ))} */}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
