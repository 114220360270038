import Details from "./pages/business/truckFleet/details";
// import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import GuestRoutes from "./components/GuestRoutes";
import AppLayout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoutes";
// import Home from "./pages";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/SignUp";
import SignupNext from "./pages/auth/SignUpNext";
import BusinessProfile from "./pages/business/BusinessProfile";
import Dashboard from "./pages/business/Dashboard";
import TruckFleet from "./pages/business/truckFleet";
import Cancellations from "./pages/rental-operations/Cancellations";
// import Pending from "./pages/rental-operations/Pending";
import Rentals from "./pages/rental-operations/Rentals";
import Income from "./pages/user-profile/Income";
import MyAccount from "./pages/user-profile/MyAccount";
import OrderDetails from "./pages/rental-operations/OrderDetails";
import Ongoing from "./pages/rental-operations/Ongoing";
import Index from "./pages/business";
import Pending from "./pages/rental-operations/Pending";
import NotFound from "./pages/NotFound";
import ApproveOrder from "./pages/approve-order";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordProceed from "./pages/auth/ResetPassword/reset";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Index />} />
          <Route path="business">
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="business-profile" element={<BusinessProfile />} />
            <Route path="truck-fleet" element={<TruckFleet />} />
            <Route path="truck-fleet/:id" element={<Details />} />
            <Route path="income" element={<Income />} />
          </Route>
          <Route path="rentals">
            <Route path="" element={<Rentals />} />
            <Route path="details/:orderId" element={<OrderDetails />} />
            <Route path="pending" element={<Pending />} />
            <Route path="pending/details/:orderId" element={<OrderDetails />} />
            <Route path="ongoing" element={<Ongoing />} />
            <Route path="ongoing/details/:orderId" element={<OrderDetails />} />
            {/* rentals/pending/details/a5ea100 */}
            <Route path="cancellations" element={<Cancellations />} />
            <Route
              path="cancellations/details/:orderId"
              element={<OrderDetails />}
            />
          </Route>
          <Route path="user-profile">
            <Route path="my-account" element={<MyAccount />} />
            {/* <Route path="sub-users" element={<SubUsers />} /> */}
          </Route>
          <Route path="rental-operation" element={<Login />} />
          <Route path="user-profile" element={<Login />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup/proceed" element={<SignupNext />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route
          path="reset-password/proceed"
          element={<ResetPasswordProceed />}
        />
        <Route path="approve-order" element={<ApproveOrder />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
