import { Spin } from "antd";
import React from "react";
import styles from "./styles.module.css";
import favicon from "../../assets//godump-favicon.png";

export default function PageSpinner({
  showSpinner,
}: {
  showSpinner?: boolean;
}) {
  return (
    <div className={styles.contianer}>
      {!showSpinner ? (
        <img
          src={favicon}
          alt="Godump logo"
          width="35"
          className={styles.logo}
        />
      ) : (
        <Spin />
      )}
    </div>
  );
}
