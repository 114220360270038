import { Space } from "antd";
import styles from "./styles.module.css";

const CheckoutCard = ({
  // item,
  truck,
}: {
  // item?: CartItem;
  truck?: TruckData;
}) => {
  // const [truck, setTruck] = useState<TruckData>();

  // const onChangeTrips = (e: any) => {
  //   setNumOfTrips(e.target.value);
  // };

  return (
    <div className={styles["cart-item"]}>
      <aside className={styles["truck-image"]}>
        <img src={truck?.picture} alt={truck?.name} height="120" />
      </aside>
      <aside>
        <div>
          <p
            className={styles["truck-name"]}
          >{`${truck?.brand}`}</p>
          <p
            className={styles["truck-capacity"]}
          >{`${truck?.tonnage} Tonnes`}</p>
          <Space>
            <b>Head Color:</b>
            {truck?.headColor}
          </Space>
          <br />
          <Space>
            <b>Bucket Color:</b>
            {truck?.bucketColor}
          </Space>
        </div>
      </aside>
      <aside></aside>
      <aside className={styles.price}>
        {/* <h3>{`₦${item.price}`}</h3> */}
      </aside>
    </div>
  );
};

export default CheckoutCard;
