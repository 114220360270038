import dayjs from "dayjs"

const orderSearchResult = (orders:CartItem[], searchQuery:string) => {
 return orders?.filter((order) => {
    return (
      order.id?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      order.deliveryPoint?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      order.pickUpPoint?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      order.price?.toString().toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      dayjs(order.createdAt).format("DD MMM YYYY")?.toString().toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      order?.material?.name
        ?.toLowerCase()
        ?.indexOf(searchQuery.toLowerCase())! > -1 ||
      order.noOfTrips
        ?.toString()
        .toLowerCase()
        ?.indexOf(searchQuery.toLowerCase())! > -1 
    );
  })
}

export default orderSearchResult