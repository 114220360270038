import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import Profile from "./Profile";

export default function BusinessProfile() {
  const { setPageTitle } = useContext(AppContext);
  setPageTitle("Business Profile");
  return (
    <>
      {/* <div>
      <Tabs
        defaultActiveKey="1"
        // onChange={onChange}
        items={[
          {
            label: `Business Profile`,
            key: "1",
            children: <Profile />,
          },
          {
            label: `Bank Accounts`,
            key: "2",
            children: <BankAccounts />,
          },
        ]}
      />
    </div> */}
      <Profile />
    </>
  );
}
