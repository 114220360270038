import { ReactNode } from "react";
import { useState } from "react";
import { createContext } from "react";

type SideBArProp = {
  showSideBar: boolean;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  formValues: {};
  setFormValues: any;
};

const AppContext = createContext<SideBArProp>({
  showSideBar: false,
  setShowSideBar: () => null,
  pageTitle: "",
  setPageTitle: () => null,
  formValues: {},
  setFormValues: () => null,
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>("");
  const [formValues, setFormValues] = useState<{}>({});

  return (
    <AppContext.Provider
      value={{
        showSideBar,
        setShowSideBar,
        pageTitle,
        setPageTitle,
        formValues,
        setFormValues,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
