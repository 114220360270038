import { configureStore } from "@reduxjs/toolkit"
import auth from "./slices/auth"
import sidenav from "./slices/sidenav"
const store = configureStore({
  reducer: { auth, sidenav },
  devTools: process.env.REACT_NODE_ENV !== "production",
});

export default store

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>
