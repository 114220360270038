import { Table, Tooltip } from "antd";
import styles from "./styles.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";
import { useContext, useLayoutEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import axiosConfig from "../../util/axios";
import PageSpinner from "../../components/PageSpinner";
import money from "../../assets/images/cash.png";
import dbImg1 from "../../assets/images/main-owner.png";
import dbImg2 from "../../assets/images/main-renter.png";
import useOrder from "../../hooks/useOrdder";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);
interface DashboardData {
  activeTrucks: number;
  amountPaidThisMonth: number;
  amountPaidThisYear: number;
  comepletedRentals: number;
  comepletedRentalsLastMonth: number;
  comepletedRentalsThisMonth: number;
  comepletedRentalsThisYear: number;
  inActiveTrucks: number;
  message: "Successful";
  noOfOngoingRentals: number;
  totalAmountDue: number;
  totalTrucks: number;
}

const Dashboard = function Dashboard() {
  const { setPageTitle } = useContext(AppContext);
  setPageTitle("My Dashboard");

  const [data, setData] = useState<DashboardData>();
  const [isLoding, setIsLoading] = useState<boolean>(false);
  const { getAllOrders, orders, isFetching } = useOrder();

  const getDashboard = async () => {
    setIsLoading(true);
    try {
      const { data: dashbordData }: { data: DashboardData } =
        await axiosConfig.get("dashboard/truck-owner");
      setData(dashbordData);
      // setData(dashbordData)
      // data.cardData[0].value = dashbordData.pendingRenterOrders!;
      // data.cardData[1].info[0].value = dashbordData.activeTrucks;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    getDashboard();
    getAllOrders("completed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rentalsColumns: ColumnsType<CartItem> = [
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return `${dayjs(text).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "ID",
      dataIndex: "rentalId",
      key: "rentalId",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `₦${text.toLocaleString()}`;
      },
    },

    {
      title: "DROP OFF",
      dataIndex: "deliveryPoint",
      key: "deliveryPoint",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
    {
      title: "PICK UP",
      dataIndex: "pickUpPoint",
      key: "pickUpPoint",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
  ];

  if (isLoding) return <PageSpinner />;

  return (
    <div>
      <CardSection data={data!} />
      <section className={styles["lower-section"]}>
        {/* <aside className={styles["lhs"]}>
          <h3>Latest Rentals</h3>
          <Line options={lineChartOptions} data={lineChartData} />
        </aside> */}
        <aside className={styles["rhs"]}>
          <h3>Latest Rentals</h3>
          <Table
            loading={isFetching}
            pagination={false}
            columns={rentalsColumns}
            size="middle"
            style={{ minWidth: "600px" }}
            dataSource={orders?.slice(0, 5)}
          />
        </aside>
      </section>
    </div>
  );
};

const DBCard = function ({
  title,
  value,
  className = "",
  color,
  img,
  info,
}: {
  title: string;
  value?: string | number;
  className?: any;
  color?: string;
  img?: string;
  info?: { title: string; value: number | string }[];
}) {
  return (
    <div className={styles["db-card"]}>
      <aside className={styles["db-card-top"]}>
        <img src={img} alt="cash" width="50" />
        <h3>{title}</h3>
      </aside>
      <aside>
        {info?.map(({ title, value }) => (
          <div className={styles["db-card-info"]}>
            <h3 className={styles["title"]}>{title}</h3>
            <b>{title.toLowerCase() === "sales" ? `$${value}` : value}</b>
          </div>
        ))}
      </aside>
      {/* <section>
        <Line options={lineChartOptions} data={lineChartData} />
      </section> */}
    </div>
  );
};

const CardSection = ({ data }: { data: DashboardData }) => {
  return (
    <section className={styles["upper-section"]}>
      <DBCard
        title="Transaction Overview"
        img={money}
        info={[
          {
            title: "Month to Date",
            value: data?.amountPaidThisMonth,
          },
          { title: "Year to Date", value: data?.amountPaidThisYear },
          { title: "Total Earnings", value: data?.totalAmountDue },
        ]}
      />

      <DBCard
        title="Truck Overview"
        img={dbImg2}
        info={[
          { title: "Active", value: data?.activeTrucks },
          { title: "Inactive", value: data?.inActiveTrucks },
          { title: "Total Trucks", value: data?.totalTrucks },
        ]}
      />
      <DBCard
        title="Rental Operations"
        img={dbImg1}
        info={[
          // { title: "Pending", value: 0 },
          { title: "In Progress", value: data?.noOfOngoingRentals },
          { title: "Completed Rentals", value: data?.comepletedRentals },
          {
            title: "Total Rentals",
            value: data?.noOfOngoingRentals + data?.comepletedRentals,
          },
        ]}
      />
    </section>
  );
};

export default Dashboard;
