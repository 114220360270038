import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthCarousel from "../../components/AuthCarousel";
import useAuth from "../../hooks/useAuth";
import styles from "./login.module.css";
import Logo from "../../components/Icons/Logo";
// import InfoModal from "components/Modals/InfoModal"
import pendingImg from "../../assets/images/pending.png";
import requestErrorHandler from "../../util/requestErrorHandler";
// import InfoModal from "components/Modals/InfoModal";

export default function Login() {
  const navigate = useNavigate();
  const { logIn, user } = useAuth();

  if (user) navigate("/");

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  // const { token } = useToken();

  async function handleSubmit({
    adminEmail,
    adminPassword,
  }: {
    adminEmail: string;
    adminPassword: string;
  }) {
    setIsLoading(true);
    try {
      // return;
      const data = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "truck-owners/login",
        { adminEmail, adminPassword }
      );
      const { token } = data.data;
      logIn(null, token);
      message.success("Logged in successfully");
      navigate("/");
    } catch (error: any) {
      // form.setFields([{ name: "password", errors: [error] }])
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div onClick={() => setShowModal(false)} className={styles.container}>
      <section className={`${styles["left-section"]} ${styles.section}`}>
        <div className={styles.logo}>
          <Logo size="large" />
        </div>
        <h2 className={styles.title}>Truck Owner Login</h2>
        <Form onFinish={handleSubmit} className={styles.form} layout="vertical">
          <Form.Item
            label="Email"
            name="adminEmail"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
              {
                pattern:
                  /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/,
                message: "Invalid email",
              },
            ]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="adminPassword"
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button
            loading={isLoading}
            block
            size="large"
            type="primary"
            htmlType="submit"
          >
            LOGIN
          </Button>
          <div className={`c-nd ${styles["reset-password"]}`}>
            <Button type="link" onClick={() => navigate("/reset-password")}>
              Forgot Password?
            </Button>
          </div>
        </Form>
        <div className={styles["form-footer"]}>
          Don't have an account?{" "}
          <Button
            className={styles["form-footer-button"]}
            onClick={() => navigate("/signup")}
            type="link"
          >
            Sign Up
          </Button>
        </div>
      </section>
      <section className={`${styles["right-section"]} ${styles.section}`}>
        <AuthCarousel />
      </section>
      {showModal && (
        <InfoModal
          image={pendingImg}
          title="Account yet to be apporved by Admin"
          ctaLabel="Kindly contact Admin"
          handleNext={() => window.open("mailto:admin@mail.com", "_blank")}
        />
      )}
    </div>
  );
}

const InfoModal = ({
  title,
  subText,
  image,
  handleNext,
  ctaLabel,
}: {
  title?: string;
  subText?: string;
  image?: string | any;
  handleNext?: () => void;
  ctaLabel?: string | any;
}) => {
  return (
    <div className={styles["message-container"]}>
      <div className={styles["message-content"]}>
        <img src={image} alt={image} width="100" />
        <h3>{title}</h3>
        <h4>{subText}</h4>
        <Button type="link" onClick={handleNext}>
          {ctaLabel || "Next"}
        </Button>
      </div>
    </div>
  );
};
