import { Button, Space } from "antd";
import { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import styles from "./styles.module.css";
import useAuth from "../../hooks/useAuth";
import ChangeUPassword from "../../components/Modals/ChangePassword";
import ChangeUsername from "../../components/Modals/ChangeUsername";
import avatar from "../../assets//images/avatar.png";

export default function MyAccount() {
  const { user } = useAuth();
  const { setPageTitle } = useContext(AppContext);
  setPageTitle("My Account");
  const [isEditPassword, setIsEditPassword] = useState<boolean>(false);
  const [isEditUsername, setIsEditUsername] = useState<boolean>(false);
  return (
    <div>
      <div className="c-end">
        <Space>
          <Button
            onClick={() => setIsEditUsername(true)}
            type="primary"
            size="large"
          >
            Change Username
          </Button>
          <Button
            onClick={() => setIsEditPassword(true)}
            type="primary"
            size="large"
          >
            Change Password
          </Button>
        </Space>
      </div>
      <main className={styles["main-content"]}>
        <div className={styles["profile-content"]}>
          <div>
            <img src={avatar} alt="avatr" width="200" />
          </div>
          <div className={styles.card}>
            <div>
              <span>User name:</span>
              <h3>{user?.truckOwner.userName}</h3>
            </div>
            <div>
              <span>Fullname:</span>
              <h3>{`${user?.firstName} ${user?.lastName}`}</h3>
            </div>
            <div>
              <span>Phone:</span>
              <h3>{user?.phoneNumber}</h3>
            </div>
            <div>
              <span>Email:</span>
              <h3>{user?.email}</h3>
            </div>
          </div>
        </div>
      </main>
      <ChangeUPassword
        open={isEditPassword}
        onCancel={() => setIsEditPassword(false)}
        // username={user?.truckOwner.userName!}
        key={user?.id}
        email={user?.email!}
      />
      <ChangeUsername
        open={isEditUsername}
        onCancel={() => setIsEditUsername(false)}
        username={user?.truckOwner.userName!}
        key={user?.id}
      />
    </div>
  );
}
