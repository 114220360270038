

import axios from "axios";
// import { store } from "redux/store";
const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
axiosConfig.interceptors.request.use(
  function (config:any) {
    const token = localStorage.getItem("admin-token-v1");
    // const {token}= useAuth()
    // if (!token) return Promise.reject("unAuthorized");
    const headers = {
      Authorization: "Bearer " + token,
    };
    config.headers = headers!;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);


export default axiosConfig;



