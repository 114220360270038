import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Upload,
  Row,
  Col,
  Alert,
} from "antd";
import axiosConfig from "../../util/axios";
import uploadToS3 from "../../util/uploadToS3";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useState } from "react";
import requestErrorHandler from "../../util/requestErrorHandler";

type Props = {
  open: boolean;
  onCancel: () => void;
  callback: () => void;
  customerTruckId?: string;
  afterClose?: () => void;
};

export default function AddDreiverInfo({
  open,
  onCancel,
  callback,
  customerTruckId,
  afterClose,
}: Props) {
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  async function handleSubmit(values: TruckData) {
    setIsSubmitting(true);
    try {
      await axiosConfig.post("customer-trucks/add-driver", {
        ...values,
      });
      message.success("Driver Information Added successfully!");
      callback();
      onCancel();
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      footer={null}
      open={open}
      title="Add Truck Driver Informaation"
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        // initialValues={user}
      >
        {/* <Form.Item
          rules={[{ required: true, message: "Driver Name cannot be blank" }]}
          name="driverName"
          label="Driver Name"
        >
          <Input />
        </Form.Item> */}
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[
                { required: true, message: "Driver Name cannot be blank" },
              ]}
              name="driverFirstName"
              label="Driver First Name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[
                { required: true, message: "Driver Name cannot be blank" },
              ]}
              name="driverLastName"
              label="Driver Last Name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              label=" Driver Phone"
              name="driverPhone"
              rules={[{ required: true, message: "Phone cannot be empty" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="driverPhoto"
              label="Driver Image"
              rules={[
                { required: true, message: "Driver Image cannot be blank" },
              ]}
              valuePropName="file"
              getValueFromEvent={(e) => {
                const file = e.fileList[0];
                if (file?.status === "done") {
                  return file.response;
                }
              }}
            >
              <Upload
                customRequest={uploadToS3}
                listType="picture"
                multiple={false}
                maxCount={1}
                accept="image/*"
              >
                <Button icon={<ArrowUpOutlined />}>Upload Driver Image</Button>
              </Upload>
            </Form.Item>
            <Alert
              message="Please provide Driver's recent Passport not greater than 20MB"
              type="info"
              style={{ marginBottom: 20 }}
            />
          </Col>
        </Row>

        <Form.Item
          rules={[{ required: true, message: "Tonnage cannot be blank" }]}
          name="customerTruckId"
          label="Tonnage"
          initialValue={customerTruckId}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
