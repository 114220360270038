import getImageSize from "../../helpers/getImageSize";

export default function Logo({ size }: { size?: ImageSize }) {
  return (
    <div>
      <img
        src="https://materialspro-media.s3.us-west-1.amazonaws.com/godump-images/5.png"
        alt="GoDump logo"
        width={getImageSize(size)}
      />
    </div>
  );
}
