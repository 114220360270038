import { message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useQuery from "./useQuery";
import requestErrorHandler from "../util/requestErrorHandler";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const useToken = () => {
  const [verified, setVerified] = useState<boolean>();
  // const { user } = useAuth();
  // const { search } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [tokenUser, setTokenUser] = useState<TokenUser>();

  // const email =
  //   useQuery().get("email") || useQuery().get("token")?.split("=")[1];
  const email = useQuery().get("email");
  const token = useQuery().get("token");


  console.log("email ", useQuery().get("email"));

  const getToken = (_token?: string) => {
    const mainToken = token ?? _token;
    setIsLoading(true);
    axios
      .post(`${BASE_URL}truck-owners/verify-token`, {
        token: mainToken,
      })
      .then((data) => {
        setTokenUser(data.data.user);
        setVerified(true);
        message.success("Token Verified");
      })
      .catch((error) => {
        requestErrorHandler(error);
        setVerified(false);
        // navigate("/login");
        // <Redirect from="/" to="/login" noThrow />;
      })
      .finally(() => setIsLoading(false));
  };

  const runOnce = useRef(false);

  useEffect(() => {
    if (runOnce.current) return;
    getToken();
    runOnce.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { verified, isLoading, email, setIsLoading, getToken, tokenUser };
};

export default useToken;
