import React, { useContext, useEffect } from "react";
import { Divider, Input, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import AppContext from "../../context/AppContext";
import Container from "../../components/Container";
import dayjs from "dayjs";
import useOrder from "../../hooks/useOrdder";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { SearchOutlined } from "@ant-design/icons";
import orderSearchResult from "../../util/orderSearchResult";
import useSearch from "../../hooks/useSearch";

const Cancellations: React.FC = () => {
  const { setPageTitle } = useContext(AppContext);

  const navigate = useNavigate();

  const { getAllOrders, orders, isFetching } = useOrder();
  const { handleSearch, isSearching, searchQuery } = useSearch();

  // const [isLoading, setIsLoading] = useState(false);
  // const [page, setPage] = React.useState(1);
  // const [paginationSize, setPaginationSize] = useState(10);
  const columns: ColumnsType<CartItem> = [
    {
      title: "ID",
      dataIndex: "rentalId",
      key: "rentalId",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `₦${text.toLocaleString()}`;
      },
    },
    {
      title: "LOADING POINT",
      dataIndex: "pickUpPoint",
      key: "pickUpPoint",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
    {
      title: "DUMPING POINT",
      dataIndex: "deliveryPoint",
      key: "deliveryPoint",
      ellipsis: true,
      render: (text) => <Tooltip>{text}</Tooltip>,
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return `${dayjs(text).format("DD MMM YYYY")}`;
      },
    },
  ];

  useEffect(() => {
    getAllOrders("cancelled");
    setPageTitle("Cancellation Requests");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="c-end">
        <Input
          prefix={<SearchOutlined />}
          size="large"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: "40%" }}
        />
      </div>
      <Divider style={{ margin: "0.5rem" }} />
      <div className=" table-container">
        <Table
          className=" table"
          loading={isFetching}
          onRow={(record: CartItem) => {
            return {
              onClick: () => {
                navigate(`details/${record.id}`, { state: record });
              },
            };
          }}
          rowClassName={styles["table-row"]}
          columns={columns}
          dataSource={
            isSearching ? orderSearchResult(orders!, searchQuery) : orders
          }
        />
      </div>
    </Container>
  );
};

export default Cancellations;
