import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { addToken, signIn, signOut } from "../store/slices/auth";

export default function useAuth() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const logIn = useCallback(
    function (user: User|null, token?: string) {
      if (token) {
        dispatch(addToken(token));
        localStorage.setItem("admin-token-v1", token);
      }
      if (user) dispatch(signIn(user));
    },
    [dispatch]
  );

  const logOut = useCallback(() => {
    localStorage.clear();
    dispatch(signOut());
  }, [dispatch]);

  const updateToken = useCallback(
    (token: string) => {
      dispatch(addToken(token));
    },
    [dispatch]
  );
  return { user, logIn, logOut, updateToken };
}