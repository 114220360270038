import AppContext from "../../context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

// import AddCategory from "./AddCategory";
// import EditCategory from "./EditCategory";

import styles from "./styles.module.css";
import useAuth from "../../hooks/useAuth";
import AddBankAccountDetails from "../../components/Modals/AddBankAccountDetails";
import axiosConfig from "../../util/axios";
import requestErrorHandler from "../../util/requestErrorHandler";
import dayjs from "dayjs";

export default function Income() {
  const { setPageTitle } = useContext(AppContext);
  const { user } = useAuth();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [paymentRecords, setPaymentRecords] = useState<PaymentRecord[]>();

  const getPaymentRecords = async () => {
    setIsFetching(true);
    try {
      const {
        data: { data },
      } = await axiosConfig.get(
        `truck-owners/record-by-owner?truckOwnerId=${user?.truckOwner.id}`
      );
      setPaymentRecords(data);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getPaymentRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isUpdateBankDetailsModalOpen, setIsUpdateBankDetailsModalOpen] =
    useState<boolean>(false);

  const [page] = React.useState(1);
  const [paginationSize] = useState(10);

  const columns: ColumnsType<any> = [
    {
      title: "S/N",
      dataIndex: "amountPaid",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: "Amount Remaining",
      dataIndex: "amountRemaining",
      key: "amountRemaining",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_) => dayjs(_).format("DD MMM YYYY"),
    },
  ];

  setPageTitle("Income");
  return (
    <div className={styles.container}>
      <section className={styles["top-section"]}>
        <h3>Account Balance:</h3>
        <span>₦0.0</span>
      </section>
      <Button
        type="primary"
        style={{
          marginBottom: "20px",
          float: "right",
        }}
        onClick={() => setIsUpdateBankDetailsModalOpen(true)}
      >
        Update Bank Details
      </Button>
      <section className={`${styles.table} table-container`}>
        <Table
          className="table"
          columns={columns}
          dataSource={paymentRecords}
          loading={isFetching}
        />
      </section>

      <AddBankAccountDetails
        isOpen={isUpdateBankDetailsModalOpen}
        setIsOpen={setIsUpdateBankDetailsModalOpen}
        user={user!}
      />
    </div>
  );
}
