import { Modal, Form, Input, Button, message } from "antd";
import axiosConfig from "../../util/axios";
import { useState } from "react";
import requestErrorHandler from "../../util/requestErrorHandler";

type Props = {
  open: boolean;
  onCancel: () => void;
  key: any;
  email: string;
};

export default function ChangeUPassword({ open, onCancel, key, email }: Props) {
  const [form] = Form.useForm();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  interface valuesProps {
    email: string;
    oldPassword: string;
    newPassword: string;
  }
  async function handleSubmit(values: valuesProps) {
    const { email, newPassword, oldPassword } = values;
    try {
      setIsSubmitting(true);
      await axiosConfig.post(`truck-owners/change-password`, {
        email,
        newPassword,
        oldPassword,
      });
      message.success(`Password changed successfully!`);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
      onCancel();
    }
  }

  return (
    <div key={key} id={key}>
      <Modal
        // key={key}
        footer={null}
        open={open}
        title="Change Password"
        onCancel={onCancel}
      >
        <Form
          key={key}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={truckInfo!}
        >
          <Form.Item
            rules={[{ required: true, message: "Email cannot be blank" }]}
            name="email"
            label="Email"
            initialValue={email}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Old Password cannot be blank" },
            ]}
            name="oldPassword"
            label="Old Password"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "New Password cannot be blank" },
            ]}
            name="newPassword"
            label="New Password"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
            name="confirmNwPassword"
            label=" Confirm New Password"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isSubmitting}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
