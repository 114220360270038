import { ReactNode, useEffect, useRef, useState } from "react";
import PageSpinner from "../../components/PageSpinner";
import styles from "./approve.module.css";
import Logo from "../../components/Icons/Logo";
import useQuery from "../../hooks/useQuery";
import useToken from "../../hooks/useToken";
import axiosConfig from "../../util/axios";
import { message } from "antd";
import requestErrorHandler from "../../util/requestErrorHandler";

function ApproveOrder() {
  const { verified, getToken } = useToken();
  const runOnce = useRef(false);

  const [isApproved, setApproved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const type = useQuery().get("type") as "approve" | string;
  const token = useQuery().get("token");
  const orderRequestId = useQuery().get("orderRequestId");

  const handleAction = (type: "approve" | string) => {
    setIsLoading(true);
    try {
      if (type === "approve") {
        axiosConfig.put(`order/accept-order-request`, { orderRequestId });
        message.success("Request Approved");
        setApproved(true);
      } else {
        axiosConfig.put(`order/accept-order-request`, { orderRequestId });
        message.success("Request Declined");
        setApproved(false);
      }
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!verified) return;
    handleAction(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  useEffect(() => {
    if (runOnce.current) return;
    getToken(token!);
    runOnce.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isApproved) {
    return (
      <Container>
        <h1 className={styles.approved}>Request Approved</h1>
        <p>Thank you for patronizing</p>
      </Container>
    );
  }
  return (
    <Container>
      <h1 className={styles["not-approved"]}>Request Declined</h1>
      <p>
        Please contact <a href="mailto:suppoer@godump.co"> support</a> if this
        was a mistake
      </p>
    </Container>
  );
}

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.container}>
      <Logo size="large" />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ApproveOrder;
