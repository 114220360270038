import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/auth/login.module.css";
import AuthCarousel from "../AuthCarousel";
import Logo from "../Icons/Logo";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AuthContainer = ({
  children,
  title,
  showBack,
  onPressBack,
}: {
  children: JSX.Element;
  title: string;
  showBack?: boolean;
  onPressBack?: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <section className={`${styles["left-section"]} ${styles.section}`}>
        {showBack && (
          <div className={styles["back-buton"]}>
            <Button
              type="link"
              onClick={() => (onPressBack ? onPressBack() : navigate("/"))}
            >
              <ArrowLeftOutlined color="primary" /> Back
            </Button>
          </div>
        )}
        <div className={styles.logo}>
          <Logo size="large" />
        </div>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </section>
      <section className={`${styles["right-section"]} ${styles.section}`}>
        <AuthCarousel />
      </section>
    </div>
  );
};

export default AuthContainer;
