import { Button, Form, Input, Modal, Select, Typography, message } from "antd";
import { useEffect, useState } from "react";
import requestErrorHandler from "../../util/requestErrorHandler";
import axiosConfig from "../../util/axios";
import useAuth from "../../hooks/useAuth";

interface IAddBankAccountDetails {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  user: User;
}

interface Bank {
  id: number;
  name: string;
  slug: string;
  code: string;
  longcode: string;
  gateway: string;
  pay_with_bank: boolean;
  active: boolean;
  country: string;
  currency: string;
  type: string;
  is_deleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IVerifiedInfo {
  account_number: string;
  account_name: string;
  bank_id: number;
}

export default function AddBankAccountDetails({
  isOpen,
  setIsOpen,
  user,
}: IAddBankAccountDetails) {
  const [form] = Form.useForm();
  const { logIn } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [verifiedInfo, setVerifiedInfo] = useState<IVerifiedInfo>();
  const [accountNumber, setAccountNumber] = useState<string>(
    user?.truckOwner?.bankAccNumber!
  );
  const [banks, setBanks] = useState<Bank[]>();

  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(undefined);

  async function handleSubmit(values: TruckData) {
    setIsSubmitting(true);
    const payload = {
      bankAccName: verifiedInfo?.account_name,
      bankAccNumber: verifiedInfo?.account_number,
      truckOwnerId: user.truckOwner.id,
      bankName: selectedBank?.name,
    };

    try {
      await axiosConfig.post("truck-owners/add-bank-details", payload);
      message.success("Bank Details Updated Successfully!");
      const data = await axiosConfig.get("/truck-owners/logged-in");
      logIn(data.data.user);
      setIsOpen(false);
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const verifyBankDetails = async () => {
    setIsVerifying(true);
    try {
      const res = await axiosConfig.get(
        `truck-owners/verify-bank?accountNumber=${accountNumber}&bankCode=${selectedBank?.code}`
      );
      message.success("Bank Details Updated Successfully!");
      setVerifiedInfo(res.data.result.data);
    } catch (error: any) {
      setVerifiedInfo(undefined);
      requestErrorHandler(error);
    } finally {
      setIsVerifying(false);
    }
  };

  const fetchBanks = async () => {
    setIsLoadingBanks(true);
    try {
      const res = await axiosConfig.get("truck-owners/getbanks");
      setBanks(res.data.result.data);
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsLoadingBanks(false);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    if (accountNumber?.length === 10 && selectedBank) {
      verifyBankDetails();
    } else {
      setVerifiedInfo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, selectedBank]);

  return (
    <Modal
      footer={null}
      open={isOpen}
      title="Update Bank Details"
      onCancel={() => {
        form.resetFields();
        setIsOpen(false);
      }}
      // afterClose={afterClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          accountNumber: user.truckOwner.bankAccNumber,
          bank: user.truckOwner.bankName,
        }}
      >
        <Form.Item
          rules={[
            { required: true, message: "Account Number cannot be blank" },
            {
              min: 10,
              max: 10,
              message: "Account number mus be 10 characters long",
            },
          ]}
          name="accountNumber"
          label="Account Number"
        >
          <Input
            onChange={(e) => {
              setAccountNumber(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Bank"
          name="bank"
          rules={[{ required: true, message: "Bank cannot be empty" }]}
        >
          <Select
            onChange={(value) => {
              const bankPicked = banks?.find((b) => b.code === value);
              setSelectedBank(bankPicked);
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
              option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isLoadingBanks}
          >
            {banks?.map((bank) => {
              return (
                <Select.Option value={bank.code} key={bank.id}>
                  {bank.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {isVerifying ? (
          <Typography
            style={{
              paddingBottom: "20px",
            }}
          >
            verifying details...
          </Typography>
        ) : verifiedInfo ? (
          <Typography
            style={{
              color: "green",
              paddingBottom: "20px",
            }}
          >
            {verifiedInfo.account_name}
          </Typography>
        ) : null}
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
            disabled={!!!verifiedInfo}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
