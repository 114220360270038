import getImageSize from "../../helpers/getImageSize";
import cancel from "../../assets/images/cancel.png";

export default function Cancel({
  size,
  className,
  onClick,
}: {
  size?: ImageSize;
  className?: any;
  onClick?: () => void;
}) {
  return (
    <div className={className} onClick={onClick}>
      <img src={cancel} alt="cancel" width={getImageSize(size)} />
    </div>
  );
}
