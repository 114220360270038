import { message } from "antd";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import axiosConfig from "../util/axios";
import requestErrorHandler from "../util/requestErrorHandler";

const useOrder = () => {
  const navigate = useNavigate();
  const { setShowSideBar } = useContext(AppContext);

  const [order, setOrder] = useState<CartItem>();
  const [orders, setOrders] = useState<CartItem[]>();
  const [isFetching, setIsfetching] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getAllOrders = useCallback(
    async (type?: "pending" | "processing" | "completed" | "cancelled") => {
      setIsfetching(true);
      try {
        const {
          data: { orderRequests },
        } = await axiosConfig.get(
          `order/get-order-request?orderStatus=${type ?? "pending"}`
        );
        setOrders(orderRequests);
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsfetching(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );
  const getOrder = async (orderId: string, callback?: () => void) => {
    setIsfetching(true);
    try {
      const {
        data: { orderRequest },
      } = await axiosConfig.get(`order/order-request?requestId=${orderId}`);

      await callback?.();
      setOrder(orderRequest);
    } catch (error) {
      // requestErrorHandler(error);
    } finally {
      setIsfetching(false);
    }
  };

  const createOrder = useCallback(
    async (values: any, callback?: () => void) => {
      setIsSubmitting(true);
      try {
        const {
          data: { order: cartOrder },
        } = await axiosConfig.post(`order/create`, {
          ...values,
        });
        setShowSideBar(false);
        setOrder(cartOrder);
        message.success("Request Created");
        navigate(`/business/checkout/${cartOrder.id}`);
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const acceptOrder = useCallback(
    async (orderRequestId: string, callback?: () => void) => {
      setIsSubmitting(true);
      try {
        await axiosConfig.post(`order/accept-order-request`, {
          orderRequestId,
        });
        // await callback?.();
        message.info("Request Accepted");
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsSubmitting(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const declineOrder = useCallback(
    async (orderRequestId: any, callback?: () => void) => {
      setIsSubmitting(true);
      try {
        await axiosConfig.post(`order/reject-order-request`, {
          orderRequestId,
        });
        await callback?.();
        setShowSideBar(false);
        message.info("Request Declined");
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    getAllOrders,
    getOrder,
    createOrder,
    isFetching,
    isSubmitting,
    order,
    orders,
    acceptOrder,
    declineOrder,
  };
};

export default useOrder;
