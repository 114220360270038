import {
  Modal,
  Form,
  Input,
  Button,
  Upload,
  Select,
  Row,
  Col,
  Alert,
  Tooltip,
} from "antd";
import useAuth from "../../hooks/useAuth";
import uploadToS3 from "../../util/uploadToS3";
import { ArrowUpOutlined, LoadingOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import useTruckResources from "../../hooks/useTruckResources";
import requestErrorHandler from "../../util/requestErrorHandler";

type Props = {
  open: boolean;
  onCancel: () => void;
  callback: (customerTruckId?: string) => void;
};

const { Option } = Select;

export const handleMultiSelect = (e: any, type?: "material") => {
  return [
    ...e.map((value: any) =>
      type === "material"
        ? {
            materialId: value.split("&")[0],
            materialName: value.split("&")[1],
          }
        : {
            id: value.split("&")[0],
            name: value.split("&")[1],
            description: value.split("&")[2],
          }
    ),
  ];
};

export default function CreateTruck({ open, onCancel, callback }: Props) {
  const { user } = useAuth() as {
    user: User;
  };

  const truckOwnerId = user?.truckOwner?.id;

  const [form] = Form.useForm();

  // const [tonnage] = useState<number | undefined>();
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const {
    brands,
    materials,
    truckProfile,
    zones,
    isSubmitting,
    getBrands,
    getMaterials,
    getTruckProfiles,
    getZones,
    handleSubmit,
  } = useTruckResources();

  useEffect(() => {
    setLoadingData(true);
    Promise.all([getTruckProfiles(), getBrands(), getZones(), getMaterials()])
      .catch((error) => {
        requestErrorHandler(error);
        setHasError(true);
      })
      .finally(() => setLoadingData(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal footer={null} open={open} title="Create Truck" onCancel={onCancel}>
      {hasError && (
        <Alert
          type="error"
          message="Unable to load resources, Please check your connection and try again"
        />
      )}

      {loadingData && (
        <>
          Loading Resources <LoadingOutlined />{" "}
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) =>
          handleSubmit(values, undefined, "create", callback)
        }
        // initialValues={user}
      >
        {/* <Form.Item
          rules={[{ required: true, message: "Truck Tag cannot be blank" }]}
          name="vehicleTag"
          label="Truck Tag"
        >
          <Input disabled={loadingData || hasError} />
        </Form.Item> */}

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="brand"
              label="Brand"
              rules={[{ required: true, message: "Brand is required" }]}
            >
              <Select disabled={loadingData || hasError}>
                {brands?.map((brand) => (
                  <Option
                    value={brand.brandName}
                    key={brand.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {brand.brandName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="materials"
              label="Materials"
              rules={[{ required: true, message: "Materials is required" }]}
            >
              <Select
                disabled={loadingData || hasError}
                mode="multiple"
                showArrow
              >
                {materials?.map((mat) => (
                  <Option
                    value={mat.id + "&" + mat.name}
                    key={mat.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {mat.name}
                    <br />
                    {/* <small>{zone.description}</small> */}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={24} xs={24}>
            <Form.Item
              name="truckProfileId"
              label="Truck Profile"
              rules={[{ required: true, message: "Truck Profile is required" }]}
            >
              <Select disabled={loadingData || hasError}>
                {truckProfile &&
                  truckProfile?.map((profile: TruckProfile) => (
                    <Option value={profile.id} key={profile.id}>
                      {profile.type}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col md={12} xs={24}>
            <Form.Item
              initialValue={tonnage}
              rules={[
                {
                  required: true,
                  message: "Tonnage cannot be blank",
                },
              ]}
              name="tonnage"
              label="Tonnage"
            >
              <Input />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[{ required: true, message: "Year cannot be blank" }]}
              name="model"
              label="Year"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Truck Age cannot be blank",
                },
              ]}
              name="age"
              label="Truck Age"
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[
                { required: true, message: "Head Color cannot be blank" },
              ]}
              name="headColor"
              label="Head Color"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[
                { required: true, message: "Bucket Color cannot be blank" },
              ]}
              name="bucketColor"
              label="Bucket Color"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Plate Number cannot be blank",
                },
              ]}
              name="plateNumber"
              label="Plate Number"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Tooltip title="Full Image of the Truck">
              <Form.Item
                name="picture"
                label="Upload Full Truck Image"
                rules={[
                  { required: true, message: "Truck Image cannot be blank" },
                ]}
                valuePropName="file"
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <Button icon={<ArrowUpOutlined />}>
                    Upload Full Truck Image
                  </Button>
                  <br />
                  <small>
                    Image should not be greater than <b>20MB</b>
                  </small>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
          <Col md={12} xs={24}>
            <Tooltip title="Image of the Sticker by Drivers door, between the front and rear door">
              <Form.Item
                name="vehicleTag"
                label="Upload Door Jamb Sticker"
                rules={[
                  {
                    required: true,
                    message: "Door Jamb Sticker cannot be blank",
                  },
                ]}
                valuePropName="file"
                getValueFromEvent={(e) => {
                  const file = e.fileList[0];
                  if (file?.status === "done") {
                    return file.response;
                  }
                }}
              >
                <Upload
                  customRequest={uploadToS3}
                  listType="picture"
                  multiple={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <Button icon={<ArrowUpOutlined />}>
                    Upload Door Jamb Sticker
                  </Button>
                  <br />
                  <small>
                    Image should not be greater than <b>20MB</b>
                  </small>
                </Upload>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>

        <Form.Item
          rules={[{ required: true, message: "Tonnage cannot be blank" }]}
          name="truckOwnerId"
          label="Tonnage"
          initialValue={truckOwnerId}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="serviceLocation"
          label={"Geographical Coverage Area"}
          rules={[{ required: true, message: "Service Location is required" }]}
        >
          <Select
            style={{ overflowY: "scroll" }}
            disabled={loadingData || hasError}
            mode="multiple"
          >
            {zones
              ?.sort((a, b) => {
                const numA = parseInt(a?.name?.match(/\d+/)?.[0]!);
                const numB = parseInt(b?.name?.match(/\d+/)?.[0]!);
                return numA - numB;
              })
              .map((zone) => (
                <Option
                  value={`${zone.id}&${zone.name}&${zone.description}`}
                  key={zone.id}
                >
                  {zone.name}
                  <br />
                  <small>{zone.description}</small>
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            block
            type="primary"
            size="large"
            disabled={loadingData || hasError}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
