import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosConfig from "../../util/axios";
import { useState } from "react";
import styles from "./styles.module.css";
import PageSpinner from "../../components/PageSpinner";

function ProtectedRoute({ children }: any) {
  const navigate = useNavigate();

  const { logIn, updateToken, logOut, user } = useAuth();
  const [isLodaing, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const token = localStorage.getItem("admin-token-v1");

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        if (token) {
          updateToken(token);
          const data = await axiosConfig.get("/truck-owners/logged-in");
          logIn(data.data.user);
        } else {
          logOut();
        }
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
    if (!token) {
      return navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  if (isLodaing) {
    return (
      <div className={styles.container}>
        <PageSpinner />
      </div>
    );
  }

  if (!user || !token) {
    navigate("/login");
    return (
      <></>
      // <div className="c-center offline">
      //   <span>
      //     <img src={logo} alt="GoDump logo" width="200" />
      //     <br />
      //     <img src={unauthorized} alt="Signal" width="300" />
      //     <br />
      //     <p> We had issues logging you in </p>
      //     <br />
      //     <Space>
      //       <Button type="link" onClick={() => window.location.reload()}>
      //         RELOAD
      //       </Button>
      //       <Button type="primary" onClick={() => navigate("/login")}>
      //         LOGIN
      //       </Button>
      //     </Space>
      //   </span>
      // </div>
    );
  }
  return children;
}

export default ProtectedRoute;
