import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import EditProfileModal from "../../../components/Modals/EditProfileModal";
import styles from "./styles.module.css";
import useProfilePicture from "../../../hooks/useProfilePicture";

export default function Profile() {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const dp = useProfilePicture();

  const businessInfo = user?.truckOwner;

  return (
    <div>
      <div className="c-end">
        {/* <Button onClick={() => setIsEditOpen(true)} type="primary" size="large">
          Edit Profile
        </Button> */}
      </div>
      <main className={styles["main-content"]}>
        <div className={styles["profile-content"]}>
          <section className={styles.card}>
            <div>
              <img src={dp} alt="Business Logo" width="200" />
            </div>
            <div className={styles["card-info"]}>
              <div>
                <span>Fullname:</span>
                <h3>{user?.firstName + " " + user?.lastName}</h3>
              </div>
              <div>
                <span>Username:</span>
                <h3>{businessInfo?.userName}</h3>
              </div>
              <div>
                <span>Phone:</span>
                <h3>{user?.phoneNumber}</h3>
              </div>
              <div>
                <span>Email:</span>
                <h3 className={styles.email}>{user?.email}</h3>
              </div>
            </div>
          </section>
          <section className={styles.card}>
            <div className={styles["card-info"]}>
              <div>
                <span>Business Name:</span>
                <h3>{businessInfo?.businessName}</h3>
              </div>
              <div>
                <span>Business Address:</span>
                <h3>{`${businessInfo?.businessAddress} ${businessInfo?.state} State`}</h3>
              </div>
              <div>
                <span>Status:</span>
                <h3>{businessInfo?.status}</h3>
              </div>
            </div>
          </section>
          <section className={styles.card}>
            <div className={styles["card-info"]}>
              <div>
                <span>Account number:</span>
                <h3>{businessInfo?.bankAccNumber ?? "N/A"}</h3>
              </div>
              <div>
                <span>Bank Account Name:</span>
                <h3>{`${businessInfo?.bankAccName ?? "N/A"}`}</h3>
              </div>
              <div>
                <span>Bank Name:</span>
                <h3>{businessInfo?.bankName ?? "N/A"}</h3>
              </div>
            </div>
          </section>
        </div>
      </main>
      <EditProfileModal
        isEditOpen={isEditOpen}
        onCancel={() => {
          setIsEditOpen(false);
        }}
      />
    </div>
  );
}
