import { Button, Form, Input, message } from "antd";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import axiosConfig from "../../util/axios";
import AuthContainer from "../../components/AuthContainer";
import Cancel from "../../components/Icons/Cancel";
// import InfoModal from "components/Modals/InfoModal";
import sendImg from "../../assets/images/send.png";
import requestErrorHandler from "../../util/requestErrorHandler";
interface SignupFields {
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
}

export default function Signup() {
  const [registering, setRegistering] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  // const [formData, setFormData] = useState<{ fullname: string; email: string }>(
  //   { fullname: "", email: "" }
  // );
  const handleSubmit = useCallback(async (values: SignupFields) => {
    // const fullname = values.adminFirstName + "-" + values.adminLastName;
    // const email = values.adminEmail;
    // setFormData({
    //   fullname,
    //   email,
    // });
    try {
      setRegistering(true);
      await axiosConfig.post("truck-owners/register", { ...values });
      setSentEmail(true);
      message.success("Proceed to next step");
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setRegistering(false);
    }
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <AuthContainer title="Register as a Truck Owner">
        <>
          <Form
            className={styles["form"]}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              label="First Name"
              name="adminFirstName"
              rules={[{ required: true, message: "Plese enter first name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="adminLastName"
              rules={[{ required: true, message: "Plese enter last name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="adminEmail"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Plese enter a valid email",
                },
                {
                  pattern:
                    /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/,
                  message: "Invalid email",
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input />
            </Form.Item>
            <Button
              loading={registering}
              htmlType="submit"
              block
              type="primary"
              size="large"
            >
              Proceed
            </Button>
          </Form>
          <div className={styles["form-footer"]}>
            Already have an account?{" "}
            <Button
              className={styles["form-footer-button"]}
              onClick={() => navigate("/login")}
              type="link"
            >
              Sign In
            </Button>
          </div>
        </>
      </AuthContainer>
      {sentEmail && (
        <EmailMessage onClose={() => setSentEmail(false)}>
          <>
            <p>An email has been sent to you</p>
            <h4>Kindly check your inbox to proceed</h4>
          </>
        </EmailMessage>
      )}
    </>
  );
}

export const EmailMessage = ({
  children,
  onClose,
}: {
  children: JSX.Element;
  onClose?: () => void;
}) => {
  return (
    <div className={styles["message-container"]}>
      <div className={styles["message-content"]}>
        <Cancel className={styles.cancel} size="smaller" onClick={onClose} />
        <img src={sendImg} alt="" width="100" />
        {children}
        {/* <Button onClick={handleNext}>Next</Button> */}
      </div>
    </div>
  );
};
