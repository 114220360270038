import React from "react";
import Lottie from "lottie-react";
import _404Animation from "../assets/404.json";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div style={{ alignItems: "center" }} className="c-center offline">
      <span>
        <img src={logo} alt="signal" width="200" />
        <br />
        <br />
        <div style={{ width: 350 }}>
          <Lottie animationData={_404Animation} width="200" />
        </div>
        <h1>OOPS</h1>
        <br />
        <small> Page not found</small>
        <br />
        <br />
        <Button size="large" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </span>
    </div>
  );
}
