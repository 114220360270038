import { Alert, Button, Divider, message, Popconfirm, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import CheckoutCard from "../../components/CheckoutCard";
import Container from "../../components/Container";
import AppContext from "../../context/AppContext";
import styles from "./styles.module.css";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useOrder from "../../hooks/useOrdder";
import dayjs from "dayjs";
import PageSpinner from "../../components/PageSpinner";
import Driverinfo from "../../components/Modals/DriverInfo";
import axiosConfig from "../../util/axios";
import requestErrorHandler from "../../util/requestErrorHandler";
import TruckInfoCard from "../../components/TruckCard/Info";

export default function OrderDetails() {
  const { setPageTitle } = useContext(AppContext);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const {
    getOrder,
    isFetching,
    order,
    acceptOrder,
    declineOrder,
    isSubmitting,
  } = useOrder();

  const [shwoDriverModal, setShwoDriverModal] = useState<boolean>(false);
  const [showTruckInfo, setShowTruckInfo] = useState<boolean>(false);
  const [actionDone, setActionDone] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  // const callback = () => setPaymentStatus(order?.paymentStatus!);

  const callback = async () => {
    setTimeout(async () => {
      await getOrder(orderId!);
      setActionDone(true);
    }, 1000);
  };

  const handleDriverInfo = () => {
    setShwoDriverModal(true);
    // callback();
  };

  const afterDecline = () => {
    navigate(-1);
    setActionDone(true);
  };

  async function updateTrip(id: any) {
    setIsUpdating(true);
    try {
      await axiosConfig.post(`/order/update-trip`, {
        orderRequestId: id,
      });
      message.success("Trip updated");
      getOrder(orderId!);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsUpdating(false);
    }
  }

  useEffect(() => {
    setPageTitle("Request Details");
    getOrder(orderId!);
    // getOrder(orderId!, () => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return <PageSpinner />;
  }

  const showUpdateTrip =
    order?.orderStatus === "processing" &&
    order?.trackingStatus !== "delivered";

  const showSetDelivered =
    order?.noOfTrips === order?.currentTrip && order?.orderStatus !== "pending";

  const isLastTrip = order?.noOfTrips === order?.currentTrip;

  return (
    <Container className={styles.container}>
      <div className={styles.top}>
        <Button
          onClick={() => navigate(-1)}
          type="link"
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
        <section className={styles["top-info"]}>
          <h3 className={styles["top-info-amount"]}>
            ₦{" "}
            {` ${
              order?.truckOwnerPrice?.toLocaleString() ??
              order?.price?.toLocaleString()
            }`}
          </h3>
          <Space>
            {showUpdateTrip && (
              <div>
                {order?.currentTrip < 1
                  ? ` No trip in progress `
                  : ` Trip ${order?.currentTrip} in progress `}
                <Button
                  disabled={isLastTrip}
                  onClick={() => updateTrip(order.id)}
                  hidden={isLastTrip}
                  loading={isUpdating}
                >
                  UPDATE TRIP
                </Button>
              </div>
            )}
            {showSetDelivered && (
              <TrackingButton
                trackingStatus={order?.trackingStatus!}
                orderRequestId={orderId!}
                callback={callback}
              />
            )}

            {order?.orderStatus === "pending" && (
              <>
                <Button
                  disabled={isSubmitting || actionDone}
                  onClick={() => handleDriverInfo()}
                  size="large"
                  type="primary"
                  icon={<CheckCircleOutlined />}
                >
                  ACCEPT
                </Button>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => declineOrder(orderId!, afterDecline)}
                  okText="YES - Decline"
                  okButtonProps={{ danger: true }}
                  cancelButtonProps={{ type: "primary" }}
                >
                  <Button
                    disabled={isSubmitting || actionDone}
                    size="large"
                    type="primary"
                    icon={<CloseCircleOutlined />}
                    danger
                  >
                    DECLINE
                  </Button>
                </Popconfirm>
              </>
            )}
          </Space>
        </section>
      </div>

      <Divider />
      {/* {order?.orderStatus !== "pending" && (
        <OrderTracking orderStatus={order?.trackingStatus!} />
      )} */}
      <section className={styles["upper-section"]}>
        <aside>
          <span>
            <b> Request Id: </b>
            {order?.rentalId}
          </span>
          <span>
            <b> Date created: </b>
            {dayjs(order?.createdAt).format("DD MMM YYYY")}
          </span>
          <span>
            <b> Date Due: </b>
            {dayjs(order?.dateDue).format("DD MMM YYYY")}
          </span>
          <span>
            <b> Material: </b>
            {order?.material?.name ?? "--"}
          </span>
        </aside>
        <aside>
          <span>
            <b> Tonnage: </b>
            {`${order?.customerTruck?.tonnage ?? "--"} Tonnes`}
          </span>
          <span>
            <b> Number of Trips: </b>
            {order?.noOfTrips!}
          </span>
          <span>
            <b> Loading Point: </b>
            {order?.pickUpPoint!}
          </span>
          <span>
            <b> Dumping Point: </b>
            {order?.deliveryPoint!}
          </span>
        </aside>
      </section>
      <section
        onClick={() => setShowTruckInfo(true)}
        className={styles["details-main"]}
      >
        <CheckoutCard truck={order?.customerTruck} />
      </section>

      <Driverinfo
        open={shwoDriverModal}
        onCancel={() => setShwoDriverModal(false)}
        callback={async () => {
          await acceptOrder(
            orderId!,
            callback
            // navigate(`/rentals/ongoing/details/${orderId}`)!
          );
        }}
        orderRequestId={order?.id!}
        customerTruck={order?.customerTruck}
      />

      <TruckInfoCard
        onClose={() => setShowTruckInfo(false)}
        open={showTruckInfo}
        info={order}
      />
    </Container>
  );
}

const TrackingButton = ({
  trackingStatus,
  orderRequestId,
  callback,
}: {
  trackingStatus: CartItem["trackingStatus"];
  orderRequestId: string;
  callback?: () => void;
}) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleStatus = async (status: CartItem["trackingStatus"]) => {
    setIsUpdating(true);

    try {
      await axiosConfig.put("order/tracking-status", {
        trackingStatus: status,
        orderRequestId,
      });
      message.success("Tracking status Updated");
      callback?.();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsUpdating(false);
    }
  };

  switch (trackingStatus) {
    case "ongoing":
      return (
        <Button
          type="primary"
          size="large"
          loading={isUpdating}
          onClick={() => handleStatus("delivered")}
        >
          SET AS DELIVERED{" "}
        </Button>
      );
    case "delivered":
      return <Alert message="Order Delivered" />;
    default:
      return (
        <Button
          type="primary"
          size="large"
          loading={isUpdating}
          onClick={() => handleStatus("ongoing")}
        >
          START TRIP
        </Button>
      );
  }
};
