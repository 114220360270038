import { Divider, Modal } from "antd";
import PageSpinner from "../PageSpinner";
import styles from "./styles.module.css";
import avatar from "../../assets/images/avatar.png";

const Render = ({ info }: { info?: CartItem }) => {
  // const updateTonnage = async (e: any) => {
  //   const tonnage = e.target.value;
  //   try {
  //     await axiosConfig.put(`url`, { tonnage });
  //     message.success("Tonnage updated");
  //   } catch (error) {
  //     requestErrorHandler(error);
  //   }
  // };

  if (!info) {
    return <PageSpinner />;
  }
  const truck = info?.customerTruck;
  const price = info?.price;

  return (
    <div
      className={`${styles["truck-card"]} ${styles.info}`}
      style={{
        height: "auto",
        maxWidth: "auto",
      }}
    >
      <section className={styles.image}>
        <img src={truck?.picture} alt={truck?.name} width="100%" />
      </section>
      <section className={styles["truck-info"]}>
        <div className={styles["top-bar"]}>
          <div>
            <p className={styles["truck-name"]}>{truck?.name} </p>
            <p
              className={styles["truck-capacity"]}
            >{`${truck?.tonnage} Tonnes`}</p>
          </div>

          <h3>{price ? `₦${price.toLocaleString()}` : `--`}</h3>
        </div>
        <>
          <Divider style={{ margin: "5px 0" }} />
          <p className={styles["truck-model"]}>
            Model: <b> {truck?.model} </b>
          </p>
          <p className={styles["truck-model"]}>
            {" "}
            Brand: <b> {truck?.brand}</b>{" "}
          </p>
          <p className={styles["truck-model"]}>
            Head-color: <b> {truck?.headColor}</b>
          </p>
          <p className={styles["truck-model"]}>
            Bucket-color: <b> {truck?.bucketColor}</b>
          </p>
        </>
        {truck?.driverName && <DriverCard truck={truck} />}
      </section>
    </div>
  );
};

export const DriverCard = ({
  truck,
  title,
}: {
  truck: TruckData;
  title?: string;
}) => {
  return (
    <div className={styles["driver-info"]}>
      <h3>{title ?? "Driver Information"}</h3>
      <div className={styles["content"]}>
        <div className={styles["driver-photo"]}>
          <img src={truck?.driverPhoto ?? avatar} alt="" height="100" />
        </div>
        <aside>
          <p className={styles["truck-model"]}>
            Driver Name: <b> {truck?.driverName} </b>
          </p>
          <p className={styles["truck-model"]}>
            {" "}
            Driver Phone: <b> {truck?.driverPhone}</b>{" "}
          </p>
        </aside>
      </div>
    </div>
  );
};

const TruckInfoCard = ({
  open,
  onClose,
  info,
}: {
  open: boolean;
  onClose: () => any;
  info?: CartItem;
}) => {
  return (
    <Modal style={{ top: 10 }} open={open} onCancel={onClose} footer={false}>
      <Render info={info} />
    </Modal>
  );
};

export default TruckInfoCard;
