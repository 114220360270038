import { Button, Form, Input, message, Row } from "antd";
// import { PhoneInput, PhoneInputFormItem } from "@validate-phone/antd-input";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../../util/axios";
import AuthContainer from "../../../components/AuthContainer";
import useToken from "../../../hooks/useToken";
import { useEffect, useState } from "react";
import requestErrorHandler from "../../../util/requestErrorHandler";
import PageSpinner from "../../../components/PageSpinner";
import styles from "./styles.module.css";

export default function ResetPasswordProceed() {
  const [form] = Form.useForm();
  // const [isLoading] = useState(false)
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { tokenUser, verified, getToken, email } = useToken();

  const renterId = tokenUser?.id;

  async function handleSubmit(values: any | {}) {
    setIsSubmitting(true);

    await axiosConfig
      .post("/truck-owners/change-truck-password", {
        email,
        password: values.newPassword,
      })
      .then((res) => {
        message.success("Password reset successfully");
        navigate("/login");
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verified === undefined)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <PageSpinner />
      </div>
    );
  if (verified === false) {
    navigate("/");
    return <></>;
  }

  // if (user) return <Redirect from="/" to="/builders/buy" noThrow />;

  return (
    <AuthContainer showBack title="Reset Password">
      <>
        <div className="w-100">
          <Form
            // scrollToFirstError
            // eslint-disable-next-line no-template-curly-in-string
            // validateMessages={{ required: "'${label}' is required!" }}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            // name="reset-password"
          >
            <Form.Item
              initialValue={renterId}
              name="renterId"
              label="Email Address"
              // rules={[{ required: true }, { type: "email" }]}
              hidden
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: "Cannot be blank" }]}
              name="newPassword"
              label="New Password"
              dependencies={["newPassword"]}
              hasFeedback
            >
              <Input.Password size="large" type="password" />
            </Form.Item>
            <Form.Item
              // rules={[{ required: true, message: "Cannot be blank" }]}
              name="confirmNewPassword"
              label="Confirm New Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" type="password" />
            </Form.Item>
            <Row justify="space-between">
              <Form.Item>
                <Button
                  loading={isSubmitting}
                  size="large"
                  // form="reset-password"
                  type="primary"
                  htmlType="submit"
                >
                  SAVE
                </Button>
              </Form.Item>
              <Button size="large" danger onClick={() => navigate("/login")}>
                CANCEL
              </Button>
            </Row>
          </Form>
          <p className="text-center">
            {/* Already have an account? <Link to="/login">Sign in</Link> */}
          </p>
        </div>
      </>
    </AuthContainer>
  );
}
