import Carousel from "nuka-carousel";
import React from "react";
import styles from "./styles.module.css";
import img1 from "../../assets/images/main-bg10.jpg";
import img2 from "../../assets/images//main-bg1.png";
import img3 from "../../assets/images/main-bg6.jpg";

export default function AuthCarousel({
  handleClick,
}: {
  handleClick?: () => void;
}) {
  return (
    <div>
      <Carousel
        autoplay={true}
        withoutControls
        wrapAround={true}
        speed={200}

        // animation="fade"
      >
        <div className={`${styles[`slide-1`]} ${styles.slide}`}>
          <article className={styles["slider-text"]}>
            Leasing on GoDump is easy!
          </article>
        </div>
        <div className={`${styles[`slide-2`]} ${styles.slide}`}>
          <article className={styles["slider-text"]}>
            Earn millions monthly, Hassle-Free on Your Dump Trucks
          </article>
        </div>
        <div className={`${styles[`slide-3`]} ${styles.slide}`}>
          <article className={styles["slider-text"]}>
            Building your Business on GoDump is easy!
          </article>
        </div>
      </Carousel>
      {/* <div className={styles["header-overlay"]} /> */}
      <img
        src={img1}
        alt="bg"
        width="200"
        className={`${styles["right-img-2"]} ${styles.image}`}
      />
      <img
        src={img2}
        alt="bg"
        width="180"
        className={`${styles["right-img-1"]} ${styles.image}`}
      />
      <img
        src={img3}
        alt="bg"
        width="200"
        className={`${styles["right-img-3"]} ${styles.image}`}
      />
    </div>
  );
}
