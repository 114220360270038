import { Button, Layout, Menu, MenuProps, Tooltip } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Cancel from "../Icons/Cancel";
import styles from "./layout.module.css";
import {
  MailOutlined,
  PieChartOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import favicon from "../../assets/godump-favicon.png";
import useProfilePicture from "../../hooks/useProfilePicture";
import setActiveLink from "../../helpers/setActiveLink";

const { Sider } = Layout;

const LOGO_URL =
  "https://materialspro-media.s3.us-west-1.amazonaws.com/godump-images/5.png";

interface SideProps {
  showSider: boolean;
  setShowSider: any;
}
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  // getItem("Option 1", "1", <PieChartOutlined />),
  // getItem("Option 2", "2", <DesktopOutlined />),
  // getItem("Option 3", "3", <ContainerOutlined />),
  getItem("Business", "sub1", <PieChartOutlined />, [
    getItem(<Link to="business/dashboard">Dashboard </Link>, "1"),
    getItem(<Link to="business/business-profile">Business Profile</Link>, "2"),
    getItem(<Link to="business/truck-fleet">Truck Fleet</Link>, "3"),
    getItem(<Link to="business/income"> Rental Income </Link>, "4"),
  ]),

  getItem("Rental Operations", "sub2", <MailOutlined />, [
    getItem(<Link to="rentals/">Rentals</Link>, "5"),
    getItem(<Link to="rentals/pending">Pending Requests </Link>, "6"),
    getItem(<Link to="rentals/ongoing">Ongoing Rentals </Link>, "6a"),
    getItem(
      <Link to="rentals/cancellations">Cancellation Requests </Link>,
      "7"
    ),
  ]),

  getItem("User Profile", "sub3", <UserOutlined />, [
    getItem(<Link to="user-profile/my-account"> My Account </Link>, "8"),
    // getItem(<Link to="user-profile/sub-users"> Sub Users </Link>, "9"),
  ]),
];

export default function SideBar({ showSider, setShowSider }: SideProps) {
  const { logOut, user } = useAuth();
  const naviagte = useNavigate();
  const dp = useProfilePicture();

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogOut = () => {
    logOut();
    naviagte("/login");
  };

  if (!showSider) return <></>;
  return (
    <div className={styles["sider-container"]}>
      <div onClick={() => setShowSider(false)} className={styles.close}>
        <Cancel size="smaller" />
      </div>
      <Sider
        theme="light"
        trigger
        breakpoint="lg"
        collapsible={false}
        collapsed={collapsed}
        className={styles.sider}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className={styles.logo}>
          {collapsed ? (
            <img src={favicon} alt="GoDump favicon" width="30" />
          ) : (
            <img src={LOGO_URL} alt="GoDump" width="120" />
          )}
        </div>
        <div className={styles.toggle}>
          <MenuFoldOutlined onClick={toggle} />
        </div>
        <Tooltip title={`${user?.firstName}`}>
          <div className={styles["dp"]}>
            <img src={dp} alt="" width={collapsed ? "40" : "100"} />

            {!collapsed && (
              <section className={styles.title}>
                <h2> {user?.lastName} </h2>
                <p> Truck Owner</p>
              </section>
            )}
          </div>
        </Tooltip>
        <Menu
          className="show-sm"
          theme="light"
          mode="inline"
          defaultSelectedKeys={setActiveLink()}
          defaultOpenKeys={setActiveLink()}
          items={items}
          onClick={() => setShowSider(false)}
          // style={{ backgroundColor: "red" }}
        />
        <Menu
          className="show-lg"
          theme="light"
          mode="inline"
          defaultSelectedKeys={setActiveLink()}
          defaultOpenKeys={setActiveLink()}
          items={items}
          // onClick={() => setShowSider(false)}
          // style={{ backgroundColor: "red" }}
        />
        <div className={styles.logout}>
          <Button
            onClick={handleLogOut}
            danger
            type="link"
            icon={<LogoutOutlined />}
          >
            {!collapsed && "Log Out"}
          </Button>
        </div>
      </Sider>
    </div>
  );
}
