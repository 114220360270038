const setActiveLink = () => {
  const { pathname } = window.location;
  switch (pathname) {
    case "/business/dashboard":
      return ["sub1", "1"];
    case "/business/business-profile":
      return ["sub1", "2"];
    case "/business/truck-fleet":
      return ["sub1", "3"];
    case "/business/income":
      return ["sub1", "4"];
    case "/business/*":
      return ["sub1", ""];
    case "/rentals/":
      return ["sub2", "5"];
    case "/rentals/pending":
      return ["sub2", "6"];
    case "/rentals/ongoing":
      return ["sub2", "6a"];
    case "/rentals/cancellations":
      return ["sub2", "7"];
    case "/rentals/*":
      return ["sub2", ""];
    case "/user-profile/my-account":
      return ["sub3", "8"];
    case "/user-profile/*":
      return ["sub3", ""];
    default:
      return ["sub1", "1"];
  }
};

export default setActiveLink;
