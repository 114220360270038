import { message } from "antd";
import { useState } from "react";
import axiosConfig from "../util/axios";
import requestErrorHandler from "../util/requestErrorHandler";

const useTruckResources = () => {
  const [zones, setZones] = useState<ZonesType[]>();
  const [materials, setMaterials] = useState<any[]>();
  const [brands, setBrands] = useState<Brands[]>();
  const [truckProfile, setTruckProfile] = useState<TruckProfile[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getBrands = async () => {
    await axiosConfig
      .get("brand/getall")
      .then((res) => setBrands(res.data.data))
      .catch((error) => {
        requestErrorHandler(error);
      });
  };
  const getZones = async () => {
    await axiosConfig
      .get("zones/get-all")
      .then((res) => setZones(res.data.data))
      .catch((error) => {
        requestErrorHandler(error);
      });
  };
  const getTruckProfiles = async () => {
    await axiosConfig
      .get("truck-category/get-allprofile")
      .then((res) => setTruckProfile(res.data.trucks))
      .catch((error) => {
        requestErrorHandler(error);
      });
  };
  const getMaterials = async () => {
    await axiosConfig
      .get("materials/get-all")
      .then((res) => {
        setMaterials(res.data.data);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  };
  const handleMultiSelect = (e: any, type?: "material") => {
    return [
      ...e.map((value: any) =>
        type === "material"
          ? {
              materialId: value.split("&")[0],
              materialName: value.split("&")[1],
            }
          : {
              id: value.split("&")[0],
              name: value.split("&")[1],
              description: value.split("&")[2],
            }
      ),
    ];
  };

  async function handleSubmit(
    values: TruckData,
    truckInfo?: TruckData,
    requstType?: "edit" | "create",
    callback?: (id?: string) => void
  ) {
    setIsSubmitting(true);
    try {
      if (requstType === "edit") {
        await axiosConfig.put(
          `customer-trucks/update?truckId=${truckInfo?.id}`,
          {
            ...values,
            serviceLocation: handleMultiSelect(values.serviceLocation),
            materials: handleMultiSelect(values.materials, "material"),
          }
        );
        callback?.(truckInfo?.id);
        message.success(`Edited successfully!`);
      } else if (requstType === "create") {
        const { data } = await axiosConfig.post("customer-trucks/create", {
          ...values,
          serviceLocation: handleMultiSelect(values.serviceLocation),
          materials: handleMultiSelect(values.materials, "material"),
        });
        message.success("Truck created successfully!");
        callback?.(data.data.id);
      }
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return {
    zones,
    materials,
    brands,
    truckProfile,
    isSubmitting,
    getBrands,
    getMaterials,
    getTruckProfiles,
    getZones,
    handleSubmit,
  };
};

export default useTruckResources;
