import {
  Button,
  message,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Switch,
  Table,
} from "antd";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../../../util/axios";
import styles from "./styles.module.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import requestErrorHandler from "../../../util/requestErrorHandler";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditTruck from "../../../components/Modals/EditTruck";
import SkeletonInput from "antd/es/skeleton/Input";
import { DriverCard } from "../../../components/TruckCard/Info";

export default function Details() {
  const { id } = useParams();
  const [truck, setTruck] = useState<TruckData>();
  const [isFetching, setIsFetching] = useState(false);
  const [isToggling, setIsToggling] = useState<boolean>(false);
  const [showDriverModal, setShowDriverModal] = useState<boolean>(false);
  const naviogate = useNavigate();
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<{
    show: boolean;
    data: TruckData | null;
  }>({
    show: false,
    data: null,
  });
  const [isDeleting, setisDeleting] = useState<boolean>(false);

  const getTruck = useCallback(async () => {
    setIsFetching(true);

    try {
      const {
        data: { truck },
      } = await axiosConfig.get(`customer-trucks/get-one?truckId=${id}`);
      setTruck(truck);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    Promise.all([getTruck()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTruckAvailability = (id?: string) => {
    // setShowSecondaryAmountField(!showSecondaryAmountField);
    setIsToggling(true);
    axiosConfig
      .post(`customer-trucks/toggle-truck?customerTruckId=${id}`)
      .then((res) => {
        message.success("TRuck Availability Updated!");
        getTruck();
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsToggling(false);
      });
  };

  const handleDelete = async (id: string, name: string) => {
    setisDeleting(true);
    try {
      axiosConfig.delete(`customer-trucks/delete?truckId=${id}`);
      message.success(`${name} Deleted Successfully`);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setisDeleting(false);
    }
  };

  const serviceColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Button
          style={{ padding: 0 }}
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => naviogate(-1)}
        >
          Back
        </Button>

        <Space>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setEditModal({ show: true, data: truck! });
            }}
            disabled={isFetching}
            type="primary"
          >
            <EditOutlined />
            <span className="">Edit</span>
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${truck?.name} category?`}
            onConfirm={() => {
              handleDelete(truck?.id!, truck?.name!);
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              type="primary"
              danger
              loading={isDeleting}
              disabled={isFetching || isDeleting}
            >
              <DeleteOutlined />
              <span className="">Delete</span>
            </Button>
          </Popconfirm>
        </Space>
      </Row>
      <br />
      <div className={styles["details-container"]}>
        <section className={styles["left-section"]}>
          <div className={styles["info-card"]}>
            <div className={styles["info-content"]}>
              {isFetching ? (
                <SkeletonInput
                  active
                  style={{ height: "300px", width: "350px" }}
                />
              ) : (
                <div className={styles["truck-image"]}>
                  <img src={truck?.picture} alt="Truck" width="100%" />
                  <div className={styles["set-availability"]}>
                    <b>Truck Availability</b>
                    {/* <div style={{ padding: "0 0.5rem" }} /> */}
                    <Switch
                      checked={truck?.isAvailable}
                      checkedChildren={<>ON</>}
                      unCheckedChildren={<>OFF</>}
                      disabled={isToggling}
                      onChange={() => toggleTruckAvailability(truck?.id)}
                    />
                  </div>
                  <div className={styles["driver-btn"]}>
                    <Button
                      size="large"
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() => setShowDriverModal(true)}
                    >
                      View Driver Information
                    </Button>
                  </div>
                  <Modal
                    footer={false}
                    open={showDriverModal}
                    onCancel={() => setShowDriverModal(false)}
                  >
                    <DriverCard truck={truck!} />
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </section>
        <div className={styles["right-div"]}>
          <div className={styles["info-card"]}>
            {isFetching ? (
              <Skeleton active />
            ) : (
              <>
                <Entry title="Truck Name" value={truck?.name} />
                <Entry title="Truck Status" value={truck?.status} />
                <Entry title="Bucket Color" value={truck?.bucketColor} />

                <Entry title="Truck Brand" value={truck?.brand} />
                <Entry title="Truck Model" value={truck?.model} />
                <Entry title="Plate Number" value={truck?.plateNumber} />
                <Entry title="Truck Head Color" value={truck?.headColor} />
                <Entry title="Truck Bucket Color" value={truck?.bucketColor} />
                <Entry title="Truck Age" value={truck?.age} />
              </>
            )}
          </div>
        </div>
        <div
          className={`${styles["info-table"]} ${styles["service-locations"]}`}
        >
          <h3 className={styles.title}>Service Locations</h3>
          <Table
            style={{ width: "100%" }}
            columns={serviceColumns}
            dataSource={truck?.serviceLocation}
            loading={isFetching}
          />
          {/* <ul>
            {truck?.serviceLocation.map((location) => (
              <li>{location.name}</li>
            ))}
          </ul> */}
        </div>

        <EditTruck
          open={editModal.show}
          onCancel={() => setEditModal({ show: false, data: null })}
          truckInfo={editModal.data}
        />
      </div>
    </>
  );
}

const Entry = ({
  title,
  value,
}: {
  title?: string;
  value?: string | number;
}) => {
  return (
    <span className={styles["info-entry"]}>
      <b>{title}</b>
      <p>{value}</p>
    </span>
  );
};
