import React, { useContext, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import AddCategory from "./AddCategory";
// import EditCategory from "./EditCategory";
import axiosConfig from "../../../util/axios";
import AppContext from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import CreateTruck from "../../../components/Modals/CreateTruck";
import { useEffect } from "react";
import EditTruck from "../../../components/Modals/EditTruck";
import Container from "../../../components/Container";
import useSearch from "../../../hooks/useSearch";
import AddDreiverInfo from "../../../components/Modals/AddDriverInfo";
import requestErrorHandler from "../../../util/requestErrorHandler";
import useAuth from "../../../hooks/useAuth";

const TruckFleet: React.FC = () => {
  const { setPageTitle } = useContext(AppContext);
  setPageTitle("Truck Fleet");
  const navigate = useNavigate();
  const { user } = useAuth();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<{
    show: boolean;
    data: TruckData | null;
  }>({
    show: false,
    data: null,
  });

  const [isFetching, setIsFetching] = useState(false);
  const [page] = React.useState(1);
  const [paginationSize] = useState(10);
  const [trucks, setTrucks] = useState<TruckData[]>();
  const [isDeleting, setisDeleting] = useState<boolean>(false);
  const [openDriverModal, setOpenDriverModal] = useState<boolean>(false);
  const [custmerTruckId, setCustmerTruckId] = useState<string>("");

  const { handleSearch, isSearching, searchQuery } = useSearch();

  const getTrucks = async () => {
    setIsFetching(true);
    try {
      const {
        data: { data },
      } = await axiosConfig.get(
        `customer-trucks/all-approved-by-truck-owner?truckOwnerId=${user?.truckOwner.id}`
      );
      setTrucks(data);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    Promise.all([getTrucks()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (id: string) => {
    setisDeleting(true);
    try {
      await axiosConfig.delete(`customer-trucks/delete?truckId=${id}`);
      getTrucks();
      message.success(`Truck Deleted Successfully`);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setisDeleting(false);
    }
  };

  const searchResult = trucks?.filter((truck) => {
    return (
      truck.name?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      truck.tonnage
        ?.toString()
        .toLowerCase()
        ?.indexOf(searchQuery.toLowerCase())! > -1 ||
      truck.model?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1 ||
      truck.brand?.toLowerCase()?.indexOf(searchQuery.toLowerCase())! > -1
    );
  });

  const columns: ColumnsType<TruckData> = [
    {
      title: "#",
      dataIndex: "truckName",
      render: (_, record, index) => (page - 1) * paginationSize + index + 1,
      key: "picture",
    },
    {
      title: "Image",
      dataIndex: "picture",
      key: "picture",
      render: (text) => <Avatar shape="square" size={50} src={text} />,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Tonnage",
      dataIndex: "tonnage",
      key: "tonnage",
      render: (text, record) => `${text} Tonnes`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        record.status === "pending_approval" ? (
          <p style={{ color: "orange" }}>Pending</p>
        ) : (
          <p style={{ color: "green" }}>Approved</p>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <EditCategory category={record} getCategories={getCategories} /> */}
          <Space>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setEditModal({ show: true, data: record });
              }}
              type="primary"
            >
              <EditOutlined />
              <span className="">Edit</span>
            </Button>
            <Popconfirm
              title={`Are you sure you want to delete this truck?`}
              onConfirm={(e) => {
                e?.stopPropagation();
                handleDelete(record.id);
              }}
              onCancel={(e) => e?.stopPropagation()}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{ danger: true }}
            >
              <Button
                onClick={(e) => e.stopPropagation()}
                type="primary"
                danger
                loading={isDeleting}
              >
                <DeleteOutlined />
                <span className="">Delete</span>
              </Button>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      <div className="c-space">
        <Input
          prefix={<SearchOutlined />}
          size="large"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: "40%" }}
        />
        <Button onClick={() => setShowModal(true)} type="primary" size="large">
          Add Truck
        </Button>
      </div>
      <Divider style={{ margin: "0.5rem" }} />
      <div className="table-container">
        <Table
          className="table"
          columns={columns}
          dataSource={isSearching ? searchResult : trucks}
          loading={isFetching}
          onRow={(item) => {
            return {
              onClick: (event) => {
                navigate(item.id);
              },
            };
          }}
        />
      </div>

      <CreateTruck
        callback={(id) => {
          setOpenDriverModal(true);
          setCustmerTruckId(id!);
          setShowModal(false);
        }}
        open={showModal}
        onCancel={() => setShowModal(false)}
      />
      <div key={editModal.data?.id} id={editModal.data?.id}>
        <EditTruck
          open={editModal.show}
          onCancel={() => setEditModal({ show: false, data: null })}
          truckInfo={editModal.data}
          callback={() => {
            getTrucks();
            setEditModal({ show: false, data: null });
          }}
        />
      </div>
      <AddDreiverInfo
        open={openDriverModal}
        onCancel={() => setOpenDriverModal(false)}
        callback={getTrucks}
        customerTruckId={custmerTruckId}
        afterClose={getTrucks}
      />
    </Container>
  );
};

export default TruckFleet;
