import React, { useContext, useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.css";
import useAuth from "../../hooks/useAuth";
import AppContext from "../../context/AppContext";
import SideBar from "./SideBar";
import debounce from "lodash.debounce";

const { Content } = Layout;

const AppLayout: React.FC = () => {
  const { pageTitle } = useContext(AppContext);
  const { user } = useAuth();

  const [showSider, setShowSider] = useState<boolean>(true);

  useEffect(() => {
    const checkWidth = debounce(() => {
      if (window.innerWidth < 767) {
        setShowSider(false);
      } else {
        setShowSider(true);
      }
    }, 1000);
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);

  const renderGreeting = () => {
    return `Hello ${user?.truckOwner?.businessName} 👋`;
  };

  return (
    <Layout hasSider>
      <SideBar setShowSider={setShowSider} showSider={showSider} />
      <Layout className="site-layout">
        <Content style={{ overflow: "initial" }}>
          <header>
            <span className={styles["page-title"]}>{pageTitle}</span>
            <Space>
              <h1>{renderGreeting()}</h1>
              <div className="show-sm">
                <MenuOutlined onClick={() => setShowSider(!showSider)} />
              </div>
            </Space>
          </header>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
